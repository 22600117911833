import React, {useEffect, useState} from "react"
import './index.scss'

import NavBar from '../../Home/Navbar/Navbar'
import Footer from '../../Home/Footer/Footer'
import {Col, Container, Row} from 'react-bootstrap'
import userContext from "../../Auth/Signup/Signupdata/Usecontext"
import InputGroup from "react-bootstrap/InputGroup"
import Form from "react-bootstrap/Form"
import Tooltip from "@mui/material/Tooltip"
import Modal from "react-bootstrap/Modal"
import Button from "react-bootstrap/Button"
import axios from "axios"
import {CommonConstants} from '../../../Constants/common.constants'
import Loader from '../../Loader/Loader'
import first1 from "../../../assets/images/moneyVector.svg"
import second2 from "../../../assets/images/moneyVector.svg"
import third3 from "../../../assets/images/moneyVector.svg"
import fourth4 from "../../../assets/images/moneyVector.svg"
import fifth5 from "../../../assets/images/moneyVector.svg"
import info from "../../../assets/images/info11.svg"
import tickIcon from "../../../assets/images/mdi_tick-circle-outline.svg"
import editBtn from "../../../assets/images/editBG.svg";
import deleteBtn from "../../../assets/images/deletBg.svg";
import plus from "../../../assets/images/PlusBG.svg";
import Select from "react-select";
import firstPay from "../../../assets/images/payto-icon 1.svg";
import drpa from "../../../assets/images/drparrw.svg";
import thirdPay from "../../../assets/images/payId.svg";
import moment from "moment/moment";
import copy from "../../../assets/images/copyclipboard.svg";
import UploadFiles from "../../../Helpers/UploadFiles/UploadFiles";
import fifthPay from "../../../assets/images/poli.svg";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

export default function WhatWeDo() {
  const [loading, setLoading] = useState(false)
  const [receiverFormLoading, setReceiverFormLoading] = useState(false)
  const [showPaytoAgreementDetail, setShowPaytoAgreementDetail] = useState(false)
  const [sendingAmount, setSendingAmount] = useState(100)
  const [receivingAmount, setReceivingAmount] = useState(0)
  const [selectedReceiver, setSelectedReceiver] = useState('')
  const [receivers, setReceivers] = useState([])
  const [deleteReceiverPopup, setDeleteReceiverPopup] = useState(false)
  const [selectedReceiverDeliveryMethod, setSelectedReceiverDeliverMethod] = useState('')
  const [receiverAddPopup, setReceiverAddPopup] = useState(false)
  const [receiverRelations, setReceiverRelations] = useState([])
  const [states, setStates] = useState([])
  const [banks, setBanks] = useState([])
  const [inputDetails, setInputDetails] = useState([])
  const [purposeOfTransfers, setPurposeOfTransfers] = useState([])
  const [selectedReceivingCountry, setSelectedReceivingCountry] = useState({
    "transferToCountry": "True",
    "zipCodeRequired": "False",
    "indicativeRateAvailable": "True",
    "id": 450,
    "name": "ARUBA",
    "iso3": "ABW",
    "iso2": "AW",
    "numericCode": "None",
    "phoneCode": "None",
    "capital": "Oranjestad",
    "currency": "AWG",
    "currencyName": "AWG",
    "currencySymbol": "AWG",
    "tld": ".aw",
    "region": "Americas",
    "subregion": "Caribbean",
    "latitude": "12.50000000",
    "longitude": "-69.96666666",
    "emoji": "??",
    "emojiU": "U+1F1E6 U+1F1FC",
    "enabled": false,
    "moneyGramId": "036"
  })
  const [showDeliveryPopup, setShowDelivery] = useState(false)
  const [countries, setCountries] = useState(false)
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState({
    "id": 2963,
    "name": "Pay To",
    "logo": "/downloadFile/payment_method_1701863791687.png",
    "enabled": true
  })
  const [selectedDeliveryMethod, setSelectedDeliveryMethod] = useState({
    "name": "Payout in  AUD, 10 Minute Service (WILL_CALL)",
    "value": "WILL_CALL",
    "charge": "15.00",
    "receiverAgentId": null,
    "receivingCurrency": "036",
    "exchangeRate": "1.000000",
    "country": null,
    "transferSendCurrency": null,
    "transferSendAmount": null,
    "displayName": "Cash Pickup"
  })
  const [paymentMethods, setPaymentMethods] = useState([])
  const [deliveryMethods, setDeliveryMethods] = useState([])
  const [showPaymentPopup, setShowPaymentPopup] = useState(false)
  const [paymentServiceCharge, setPaymentServiceCharge] = useState(0)
  const handleCloseDelivery = () => setShowDelivery(false)

  const handleClosePaymentPopup = () => setShowPaymentPopup(false)
  const openDeliveryPopup = () => {
    window.scrollTo(0, 0)

    setShowDelivery(true)
  }

  const onReceiverSelect = (receiverId, deliverMethod) => {
    setSelectedReceiver(receiverId);
    setSelectedReceiverDeliverMethod(deliverMethod)
  }

  const onReceiverUpdate = (receiverId) => {
    setDeleteReceiverPopup(true);
    setSelectedReceiver(receiverId);
  }

  const onReceiverDelete = () => {

  }

  const onStateChange = () => {

  }

  const validatePaytoAgreement = () => {
    setShowPaytoAgreementDetail(true)
  }

  const handlePaymentMethodChange = (paymentMethod) => {
    setSelectedPaymentMethod(paymentMethod)
  }

  const openPaymentPopup = () => setShowPaymentPopup(true)

  const handleDeliveryChange = (deliveryMethod) => {
    setSelectedDeliveryMethod(deliveryMethod)
  }

  const onSendingAmountChange = (e) => {
    setSendingAmount(e.target.value)
  }

  const onReceivingAmountChange = (e) => {
    setReceivingAmount(e.target.value)
  }

  const onReceivingCountryChange = (e) => {
    setLoading(true)

    const selectedCountry = countries.find(country => {
      return country.name === e.target.value
    })

    // TODO Send selected country ID here
    getDeliveryMethod()

    setSelectedReceivingCountry(selectedCountry)
    setLoading(false)
  }

  const [backDisplay, setBackDisplay] = useState(false)

  const onBackDisplay = (value) => {
    setBackDisplay(value)
  }

  const handleStepClick = (index, stepKey) => {
    const selectedStep = steps[index]

    if (selectedStep.isDone) {
      setBackDisplay(true)
      setActiveStep(selectedStep)
      document.getElementById(activeStep.key).style.display = "none"
      document.getElementById(stepKey).style.display = "block"
    }
  }

  const imageFirstComponent = () => {
    return <img src={first1} className="d-block m-auto" />;
  };
  const imageSecondComponent = () => {
    return <img src={second2} className="d-block m-auto" />;
  };
  const imageThirdComponent = () => {
    return <img src={third3} className="d-block m-auto" id="3rdimage" />;
  };
  const imageFourthComponent = () => {
    return <img src={fourth4} className="d-block m-auto" />;
  };

  const imageFifthComponent = () => {
    return <img src={fifth5} className="d-block m-auto" />;
  };

  const [steps, setSteps] = useState([
    {
      key: "firstStep",
      label: "Calculate",
      icon: first1,
      isDone: true,
      isRemain: true,
      components: imageFirstComponent,
    },
    {
      key: "secondStep",
      label: "Recipient ",
      icon: second2,
      isDone: false,
      isRemain: false,
      components: imageSecondComponent,
    },
    {
      key: "newStep",
      label: "Purpose",
      icon: third3,
      isDone: false,
      isRemain: false,
      components: imageSecondComponent,
    },
    {
      key: "thirdStep",
      label: "Summary",
      icon: third3,
      isDone: false,
      isRemain: false,
      components: imageThirdComponent,
    },
    {
      key: "fourthStep",
      label: "Payment",
      icon: fourth4,
      isDone: false,
      isRemain: false,
      components: imageFourthComponent,
    }
  ])

  const [activeStep, setActiveStep] = useState(steps[0])

  const getDeliveryMethod = (transferCurrency = '036', toCountry = '036') => {
    axios.post(
      CommonConstants.NEW_BASE_URL + "/moneygram/deliveryOption", {
        "amount": sendingAmount,
        "transferSendCurrency":  transferCurrency,
        "transferToCountry": toCountry
      }
    ).then(res => {
      setDeliveryMethods(res.data)

      const highestExchangeRateMethod = res.data.reduce((max, obj) => (obj.value > max.value ? obj : max), res.data[0])

      setSelectedDeliveryMethod(highestExchangeRateMethod)
    })
  }

  const getPaymentServiceCharge = (paymentMethod = selectedPaymentMethod.name) => {
    axios.get(
      CommonConstants.BASE_URL + `/serviceCharge/14/${selectedReceivingCountry.id}/${sendingAmount}/${paymentMethod}`
    ).then(res => {
        setPaymentServiceCharge(res.data.totalServiceCharge)
      })
  }

  const getCountries = () => {
    setLoading(true)
    axios.get(
      CommonConstants.BASE_URL + "/getcountries"
    ).then(res => {
      setCountries(res.data.data)

      setLoading(false)
    }).finally(() => {
      setLoading(false)
    })
  }

  const onNextClick = () => {
    window.scrollTo(0, 0)

    if (activeStep.key === 'firstStep') {
      setBackDisplay(true)
      setActiveStep(steps[1])
      document.getElementById('firstStep').style.display = "none"
      document.getElementById('secondStep').style.display = "block"
    }

    if (activeStep.key === 'secondStep') {
      setBackDisplay(true)
      setActiveStep(steps[2])
      document.getElementById('firstStep').style.display = "none"
      document.getElementById('secondStep').style.display = "none"
      document.getElementById('thirdStep').style.display = "block"
    }
  }

  const onBackClick = () => {
    if (activeStep.key === 'secondStep') {
      setBackDisplay(false)
      setActiveStep(steps[0])
      document.getElementById('secondStep').style.display = "none"
      document.getElementById('firstStep').style.display = "block"
    }

    if (activeStep.key === 'thirdStep') {
      setBackDisplay(false)
      setActiveStep(steps[1])
      document.getElementById('secondStep').style.display = "block"
      document.getElementById('thirdStep').style.display = "none"
      document.getElementById('firstStep').style.display = "none"
    }
  }

  const getPaymentMethods = () => {
    const data = {
      fromCountryId: 14,
      userId: localStorage.getItem("Id"),
    }

    axios.post(
      CommonConstants.BASE_URL + "/getallactivepaymentmethods",
      data
    ).then(response => {
      if (response.data.status === true) {
        const Payments = response.data.data.map((payment) => ({
          id: payment.id,
          name: payment.name,
          logo: payment.logo,
          enabled: payment.enabled,
        }))

        setPaymentMethods(Payments)
      }
    })
  }

  const getReceivers = () => {
    const UserData = new FormData();
    UserData.append("userId",  localStorage.getItem("Id"));
    UserData.append("isDeleted", false);

    axios.post(
      CommonConstants.BASE_URL + "/getalluserrecipientsbyuserid",
      UserData
    ).then(response => {
      if (response.data.status === true) {
        setReceivers(response.data.data)
      }
    })
  }

  const onOpenReceiverPopup = async () => {
    setReceiverAddPopup(true)
    setReceiverFormLoading(true)

    await getDeliveryMethodExtraParameters().then(() => {
      setReceiverFormLoading(false)
    })

    setReceiverFormLoading(false)
  }

  const handleCloseReceiverAddPopup = () => {
    setReceiverAddPopup(false)
  }

  const receiverInput = () => {
    return {
      fullName: '',
      middleName: '',
      lastName: '',
      state: '',
      address: '',
      city: '',
      country: '',
      purpose_of_transaction: '',
      account_number: '',
      bank_code: '',
      bank_name: ''
    }
  }

  const submitReceiver = () => {
    setLoading(true)
    const receiverData = {
      type: "individual",
      groupId: +localStorage.getItem("Id"),
      userId: +localStorage.getItem("Id"),
      firstName: receiverData.firstName,
      middleName: receiverData.middleName,
      lastName: receiverData.lastName,
      fullName: "[]",
      businessName: "",
      address: '',
      relation: "",
      phone: '',
      email: '',
      relationId: '',
      countryId: '',
      stateId: '',
      postalCode: '',
      city: '',
      deliveryMethodId: '',
      deliverymethodname: selectedDeliveryMethod.displayName,
      bankId: selectedDeliveryMethod.displayName,
      bankAccNo: '',
      haveIfscCode: false,
      ifscCode: false,
      bankStateId: '',
      district: '',
      bankBranch: '',
      bankDetailsVerified: false,
      otherDetails: "",
      oldRecipientsId: 0,
      walletName: '',
      walletNo: '',
    }

    const response = axios.post(
      CommonConstants.BASE_URL + "/adduserrecipients",
      receiverData
    );
    if (response.data.status === true) {
      setLoading(false)
      setReceiverAddPopup(false)
    }
  }

  const requiredFieldsTypes = [
    'BANKNAME',
    'accountNumber',
    'SenderRelationship',
    'TransferSendPurpose',
    'ReceiverZipCode',
    'BANKCODE',
    'BRANCHNAME',
    'WPBANKNAME',
    'WPBIC',
    'ReceiverState',
    'ReceiverAddress',
    'ReceiverCity',
    'ReceiverCountry',
    'ReceiverPhone'
  ]

  const getDeliveryMethodExtraParameters = async () => {
    const data = {
      deliveryOptionCode: selectedDeliveryMethod.value,
      transferAmount: `${sendingAmount}`,
      transferCurrency: selectedDeliveryMethod.receivingCurrency,
      transferSendAmount: `${sendingAmount}`,
      transferSendCurrency: '036',
      receiverAgentId: selectedDeliveryMethod.receiverAgentId,
      transferToCountry: '450'
    }
    await axios.post(
      CommonConstants.NEW_BASE_URL + "/moneygram/methodInfoRequest",
      data
    ).then(response => {
      const requiredFields = response.data && response.data.filter(field => {
        if (field.required === true && requiredFieldsTypes.includes(field.name)) {

          return field
        }
      })

      setInputDetails(requiredFields)

      console.log(requiredFields)
    })
  }

  useEffect( () => {
    window.scrollTo(0, 0)

    getCountries()
    getPaymentMethods(4)
    getDeliveryMethod('036', '450')
    getPaymentServiceCharge()
    getReceivers()

  }, [])

  return (
    <>
      <secton className="mainLoginSection1">
        <NavBar></NavBar>
        { loading ?   <Loader /> :  <Container>
          <Row className="respoChildFooter">
            <Col className="col-lg-12">
              <h1 className="purpleText bolder text-center mt-3 pt-3 pb-3">
                Send Money
              </h1>
            </Col>
          </Row>

          <div className="mainLoginBox mt-5 mb-5">
            <div className="box row d-flex p-0 m-0 ">
              <Row className="secondBlock ms-0 border-0">
                <Col className="col-lg-5">
                  <div className="stepsmain stepsSendMoney pb-0">
                    <span id="sevennstep">
                      <ul
                        className="nav d-flex justify-content-evenly py-5 pt-4 pb-2 sevennstep"
                        id="sevennstep"
                      >
                       {steps.map((step, i) => {
                         return (
                           <li
                             key={i}
                             className={`${activeStep.key === step.key
                               ? step.key + "active"
                               : step.isDone
                                 ? step.key + "active"
                                 : ""
                             } ${step.isDone ? "done" : ""} ${step.isRemain ? "" : step.key + "remain"
                             }`}
                             onClick={() => handleStepClick(i, step.key)}
                           >
                             <div className="step3 fourthStepremain" id="sevensteplogo" ></div>
                             <p className="stepLabel text-black mt-2">
                               {step.label}
                             </p>
                           </li>
                         );
                       })}
                      </ul>
                    </span>
                  </div>

                  <div className="row mt-3 mb-5 moneySendRespo m-auto justify-content-center">
                    <div className="col-lg-12 my-5 py-5  m-auto justify-content-center mt-0 pt-0">
                      {activeStep.components()}
                    </div>
                  </div>
                </Col>
                <div className="col-lg-7 p-0 pb-5">
                  <userContext.Provider
                    value=""
                  >
                    <div className="step-component">

                      <div id="firstStep">
                        <Container>
                          <Col className="pt-3 noPadding">
                            <div className="mainMoneyBox py-4">
                              <div className={`firstMoneyBox me-3 ms-3`}>
                                <InputGroup className="my-2 align-items-center ">
                                  <div className="countryFlag ms-3 img-fluid">
                                    <img
                                      src={`https://flagcdn.com/40x30/au.png`}
                                      style={{width: "30px", marginRight: "5px"}}
                                    />
                                  </div>
                                  <div className="position-relative d-flex mt-2 justify-content-between">
                                    <div
                                      className="moneyBoxLabel"
                                      style={{
                                        position: "absolute",
                                        top: "-3px",
                                        left: "3px",
                                        padding: "0 5px",
                                      }}
                                    >
                                      You Send
                                    </div>
                                    {" "}
                                    {/* Label */}
                                    <Form.Control
                                      style={{fontSize: 22, fontWeight: "bold"}}
                                      aria-label="Text input with dropdown button"
                                      className="bg-transparent moneysentvalue textInputMoney border-0 mt-2"
                                      placeholder="0"
                                      value={sendingAmount}
                                      min={0}
                                      onChange={onSendingAmountChange}
                                    />

                                  </div>
                                  <div className="d-flex ms-auto">
                                    <Form.Select
                                      className="select-country bolder text-black"
                                      aria-label="Default select example"
                                      name="SMfromCountry"
                                    >

                                    </Form.Select>
                                    <div className="caretDRP"></div>
                                  </div>
                                </InputGroup>

                              </div>

                              <div className="secondMoneyBox my-4 py-3">
                                <div className="text-white text-center excText responsiveFontLarge">
                                  Exchange rate:
                                  <b className="bolder responsiveFontLarge"></b>
                                  &nbsp; {selectedDeliveryMethod.exchangeRate} {selectedReceivingCountry.currency}
                                </div>
                              </div>

                              <div className="firstMoneyBox me-3 ms-3">
                                <InputGroup className="my-2 align-items-center">
                                  <div className="countryFlag ms-3 img-fluid">
                                    <img
                                      src={`https://flagcdn.com/40x30/ax.png`}
                                      style={{width: "30px", marginRight: "5px"}}
                                    />
                                  </div>
                                  <div className="position-relative mt-2 d-flex justify-content-between">
                                    <div
                                      className="moneyBoxLabel"
                                      style={{
                                        position: "absolute",
                                        top: "-3px",
                                        left: "3px",
                                        padding: "0 5px",
                                      }}
                                    >
                                      They Receive
                                    </div>
                                    {}
                                    {/* Label */}
                                    <Form.Control
                                      style={{fontSize: 22, fontWeight: "bold"}}
                                      aria-label="Text input with dropdown button"
                                      className="bg-transparent moneysentvalue mt-2 textInputMoney border-0"
                                      placeholder="0"
                                      value={sendingAmount * selectedDeliveryMethod.exchangeRate}
                                      onChange={onReceivingAmountChange}
                                    />
                                  </div>

                                  <div className="d-flex ms-auto">
                                    <Form.Select
                                      className="select-country bolder text-black"
                                      value={selectedReceivingCountry.name}
                                      aria-label="Default select example"
                                      name="SMtoCountry"
                                      onChange={
                                        onReceivingCountryChange
                                      }
                                    >
                                      {countries && countries.map((country, index) => (
                                        <option
                                          key={index}
                                          defaultValue={country.id}
                                        >
                                          {country.name}
                                        </option>
                                      ))}
                                    </Form.Select>
                                    <div className="caretDRP"></div>
                                  </div>
                                </InputGroup>
                              </div>
                            </div>
                            <p
                              className="text-center PremiumExchangerateNote m-0 bolder"
                            >

                            </p>
                          </Col>
                        </Container>

                        <Container>
                          <Col className="pt-3 pb-3">
                            <div className="mainMoneyBox">
                              <div className=" py-4 ">
                                <div className="d-flex align-items-center justify-content-between  pe-4 ps-4">
                                  <div
                                    className="d-flex align-items-center responsiveFontLarge  textInputMoney border-0 text-black-50 bolder ">
                                    <img src="https://dev.legalremit.com/static/media/debitCard.072b6b01.svg"
                                         className="img-fluid" alt=""/>
                                    <div className="ps-2">
                                      {selectedDeliveryMethod.displayName}
                                    </div>
                                  </div>
                                  <div
                                    className="d-flex align-items-center justify-content-end ps-4"
                                  >
                                    <div
                                      className="responsiveFontLarge textGray bolder  me-2 purpleText bolder pointer"
                                      onClick={openDeliveryPopup}
                                    >
                                      Change
                                    </div>
                                    <Tooltip
                                      placement="top"
                                      title='The "Delivery Method" refers to the manner in which you wish to transmit funds to your designated recipient. You have the option to select the preferred delivery method of your recipient.'
                                    >
                                      <img src="https://dev.legalremit.com/static/media/info11.950b08f7.svg"
                                           className="img-fluid" alt=""/>
                                    </Tooltip>
                                  </div>
                                </div>
                              </div>
                              <div className="pt-2 pb-4 d-flex justify-content-between align-items-center ">
                                <div className="d-flex align-items-center  pe-4 ps-4">
                                  <img src="https://dev.legalremit.com/static/media/debitCard.072b6b01.svg"
                                       className="img-fluid" alt=""/>
                                  <Form.Control
                                    aria-label="Text input with dropdown button"
                                    className="bg-transparent paymentMethod textInputMoney border-0 text-black-50 bolder py-0"
                                    placeholder="They Receive"
                                    readOnly
                                    value={
                                      selectedPaymentMethod.name
                                    }
                                  />
                                </div>
                                <div
                                  className="d-flex align-items-center justify-content-end pe-4 ps-4"
                                  onClick={openPaymentPopup}
                                >
                                  <div className="responsiveFontLarge textGray bolder me-2 purpleText bolder pointer">
                                    Change
                                  </div>
                                  <Tooltip
                                    placement="top"
                                    title='The "Payment Method" is a mechanism through which you can remit payments to Legal Remit. You have the flexibility to select a payment option that aligns with your convenience.'
                                  >
                                    <img src="https://dev.legalremit.com/static/media/info11.950b08f7.svg"
                                         className="img-fluid" alt=""/>
                                  </Tooltip>
                                </div>
                              </div>
                            </div>
                          </Col>
                          <Col className="promoPLeft">
                            <div className="pe-4 ps-4 pt-2 pb-4 d-flex justify-content-between align-items-center">
                              <div className="d-flex align-items-center  ">
                                <img src="https://dev.legalremit.com/static/media/timer.06d4344d.svg"
                                     className="img-fluid" alt=""/>
                                <div className="responsiveFontLarge  successText  ms-2">
                                  Estimated Delivery time
                                </div>
                                <Tooltip
                                  placement="top"
                                  title="This is an estimation based on normal scenarios . Our 99% of transactions get delivered within this time frame."
                                >
                                  <img src="https://dev.legalremit.com/static/media/info11.950b08f7.svg"
                                       className="img-fluid" alt=""/>
                                </Tooltip>
                              </div>
                              <div className="responsiveFontLarge  successTextModel text-end bolder ms-2 pe-4 ps-4">
                                Within 2 Business Days
                              </div>
                            </div>
                          </Col>

                          <Col className="pt-3 pb-3">
                            <div className="mainMoneyBox">
                              <div className=" pt-4 d-flex justify-content-between align-items-center">
                                <div className="d-flex align-items-center  ps-4">
                                  <div className="responsiveFontLarge  textGray bolder ms-2">
                                    Transfer Amount:
                                  </div>
                                </div>
                                <div className="d-flex align-items-center justify-content-end pe-4 ps-4">
                                  <div
                                    className="responsiveFontLarge  text-black bolder me-2 purpleText bolder eventNone">
                                    {sendingAmount} {selectedReceivingCountry ? selectedReceivingCountry.currency : ""}{" "}
                                  </div>
                                </div>
                              </div>
                              <div className=" pt-4 d-flex justify-content-between align-items-center">
                                <div className="d-flex align-items-center ps-4">
                                  <div
                                    className="responsiveFontLarge  textGray bolder ms-2"
                                  >
                                    Service Charge:
                                  </div>
                                </div>
                                <div className="d-flex align-items-center justify-content-end pe-4 ps-4">
                                  <div
                                    className="responsiveFontLarge  text-black bolder me-2 purpleText bolder eventNone">
                                    {(parseFloat(paymentServiceCharge) + parseFloat(selectedDeliveryMethod.charge)).toFixed(2)} {selectedReceivingCountry ? selectedReceivingCountry.currency : ""}{" "}
                                  </div>
                                </div>
                              </div>
                              <div
                                className={` pt-4 d-flex justify-content-between align-items-center`}
                              >
                                <div className="d-flex align-items-center  ps-4">
                                  <div className="responsiveFontLarge  textGray bolder ms-2">
                                  </div>
                                </div>
                                <div className="d-flex align-items-center justify-content-end pe-4 ps-4">
                                  <div
                                    className="responsiveFontLarge  text-black bolder me-2 purpleText bolder eventNone">

                                  </div>
                                </div>
                              </div>
                              <div
                                className="pt-3 pb-3 d-flex justify-content-between align-items-center purpleBackground bottomBg">
                                <div className="d-flex align-items-center  pe-4 ps-4">
                                  <div className="responsiveFontLarge  textGray bolder ms-2 text-white">
                                    Total Payable
                                  </div>
                                </div>
                                <div className="d-flex align-items-center justify-content-end pe-4 ps-4">
                                  <h4 className="text-white bolder responsiveFontLarge">
                                    {(sendingAmount + (parseFloat(paymentServiceCharge)) + parseFloat(selectedDeliveryMethod.charge)).toFixed(2)} {selectedReceivingCountry ? selectedReceivingCountry.currency : ""}{" "}
                                  </h4>
                                </div>
                              </div>
                            </div>
                          </Col>
                          <div className="btn-component pe-5 ps-5 d-flex justify-content-center">
                            <input
                              className={`col-lg-3 uppercase ${backDisplay ? 'block' : 'd-none'}`}
                              type="button"
                              value="BACK"
                              onClick={onBackClick}
                            />

                            <input
                              onClick={onNextClick}
                              className="col-lg-3 uppercase  nextButtonStep1"
                              type="button"
                              value="NEXT"
                            />
                          </div>

                          {/* //////this is error////// */}

                          <div className="justify-content-center d-grid">
                            <div className="responsiveFontLarge d-none text-danger merror ms-2 bolder fs-6">
                              {/* Enter the {flagSelect ? flagSelect.currency:''} You Send Money */}
                              Please enter valid amount
                            </div>
                            <br/>
                            <div className="responsiveFontLarge  d-none text-danger derror ms-2 bolder fs-6">
                              Select the Delivery Method
                            </div>
                            <br/>
                            <div className="responsiveFontLarge  d-none text-danger perror ms-2 bolder fs-6">
                              Select the Payment Method
                            </div>
                          </div>
                          {/* //////////// */}
                        </Container>

                        {/* //delivery method // * */}
                        <Modal show={showDeliveryPopup} onHide={handleCloseDelivery} size="lg">
                          <Modal.Body className="modal-money-payment">
                            {deliveryMethods &&
                              deliveryMethods.map((deliveryMethod, index) => {
                                return (
                                  <div key={index}>
                                    <div
                                      className={`cardActivePurple ${
                                        selectedDeliveryMethod.value === deliveryMethod?.value
                                          ? "cardActiveSendMoney"
                                          : ""
                                      }`}
                                      onClick={() => handleDeliveryChange(deliveryMethod)}
                                    >
                                      <div
                                        className="innerCardActive py-3 my-3"
                                      >
                                        <div className="d-flex justify-content-between align-items-start pe-3 ps-3">
                                          <div className="">
                                            <div className="d-flex align-items-center">
                                              <img
                                                src={
                                                  CommonConstants.BASE_URL +
                                                  deliveryMethod.logo
                                                }
                                                className="img-fluid getLogoSize"
                                                alt=""
                                              />
                                              <div
                                                className="responsiveFontLarge text-black bolder ms-4 d-flex align-items-center">
                                                {deliveryMethod.displayName}
                                                <div className="tooltip-container">
                                                  <img
                                                    src={info}
                                                    className="tooltip-button img-fluid ms-2"
                                                    alt=""
                                                  />
                                                  <div className="tooltip-content">
                                                  </div>
                                                </div>
                                              </div>
                                            </div>

                                            <br></br>
                                            <div className="d-flex flex-column excLeft">
                                              <div className="d-flex pb-2 justify-content-between">
                                                <div className="textGray bolder responsiveFontLarge">
                                                  Exchange rate :
                                                </div>
                                                {" "}
                                                &nbsp;
                                                <div className="textGray bolder responsiveFontLarge">
                                                  {deliveryMethod.exchangeRate ? deliveryMethod.exchangeRate : ''} {selectedReceivingCountry.currency}
                                                </div>
                                              </div>
                                              <div className="d-flex pb-2 justify-content-between">
                                                <div className="text-black bolder responsiveFontLarge">
                                                  Total Receivable :
                                                </div>
                                                {" "}
                                                <div className="text-black bolder responsiveFontLarge">
                                                  {sendingAmount * deliveryMethod.exchangeRate} {selectedReceivingCountry.currency}
                                                </div>
                                              </div>
                                              <div className="d-flex pb-2 justify-content-between">
                                                <div className="text-black bolder responsiveFontLarge">
                                                  Total Service Charge :
                                                </div>
                                                {" "}
                                                <div className="text-black bolder responsiveFontLarge">
                                                  {(parseFloat(paymentServiceCharge) + parseFloat(selectedDeliveryMethod.charge)).toFixed(2)} {selectedReceivingCountry.currency}
                                                </div>
                                              </div>
                                              <div className="d-flex pb-2 justify-content-between">
                                                <div className="text-black bolder responsiveFontLarge">
                                                  Total Payable :
                                                </div>
                                                {" "}
                                                <div className="text-black bolder responsiveFontLarge">
                                                  {(parseFloat(sendingAmount) + (parseFloat(paymentServiceCharge) + parseFloat(selectedDeliveryMethod.charge))).toFixed(2)} {selectedReceivingCountry.currency}
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div
                                            className={`tick ${
                                              selectedDeliveryMethod.value === deliveryMethod?.value
                                                ? "d-block"
                                                : "d-none"
                                            }`}
                                          >
                                            <img
                                              src={tickIcon}
                                              className="img-fluid"
                                              alt=""
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <hr className="py-1 pe-2 ps-2"></hr>
                                  </div>
                                );
                              })}
                          </Modal.Body>
                          <Modal.Footer>
                            <Button
                              className="mt-2 col-lg-3 d-block m-auto nextBtn1"
                              variant="primary"
                              onClick={handleCloseDelivery}
                            >
                              Select
                            </Button>
                          </Modal.Footer>
                        </Modal>
                        {/* ////////////////////// */}

                        {/* Payment Method DropDown */}
                        <Modal
                          show={showPaymentPopup}
                          onHide={handleClosePaymentPopup}
                          size="lg"
                        >
                          <Modal.Body className="modal-money-payment">
                            {paymentMethods &&
                              paymentMethods.map((PaymentMethod, index) => {
                                if (PaymentMethod?.enabled === true) {
                                  return (
                                    <div key={index}>
                                      <div
                                        className={`cardActivePurple ${
                                          selectedPaymentMethod.id === PaymentMethod.id ? "cardActiveSendMoney" : ""
                                        }`}
                                        onClick={() => handlePaymentMethodChange(PaymentMethod)}
                                      >
                                        <div
                                          className="innerCardActive py-3 my-3"
                                        >
                                          <div className="d-flex justify-content-between align-items-start py-3 ">
                                            <div className="">
                                              <div className="d-flex align-items-center">
                                                <img
                                                  src={
                                                    CommonConstants.BASE_URL +
                                                    PaymentMethod.logo
                                                  }
                                                  alt=""
                                                  className="img-fluid getLogoSize"
                                                  height={100}
                                                  width={100}
                                                />
                                                <div className="responsiveFontLarge text-black bolder ms-4">
                                                  {PaymentMethod.name}
                                                </div>
                                                <div className="tooltip-container">
                                                  <img
                                                    src={info}
                                                    alt=""
                                                    className="ms-2 tooltip-button"
                                                  />
                                                  <div className="tooltip-content">
                                                    ''
                                                  </div>
                                                </div>
                                              </div>

                                              <br></br>
                                              <div className="d-flex flex-column wallLeft">
                                                <div className="d-flex pb-2 justify-content-between">
                                                  <div className="textGray bolder">
                                                    Exchange rate
                                                    : &nbsp;  {selectedDeliveryMethod ? selectedDeliveryMethod.exchangeRate : ''} {selectedReceivingCountry.currency}
                                                  </div>
                                                </div>
                                                <div className="d-flex pb-2 justify-content-between">
                                                  <div className="text-black bolder">
                                                    Total Receivable :
                                                  </div>
                                                  {" "}
                                                  <div className="text-black bolder">
                                                    {sendingAmount * selectedDeliveryMethod.exchangeRate} {selectedReceivingCountry.currency}
                                                  </div>
                                                </div>
                                                <div className="d-flex pb-2 justify-content-between">
                                                  <div className="text-black bolder">
                                                    Total Service Charge :
                                                  </div>
                                                  {" "}
                                                  <div className="text-black bolder">
                                                    {(parseFloat(paymentServiceCharge) + parseFloat(selectedDeliveryMethod.charge)).toFixed(2)} {selectedReceivingCountry.currency}
                                                  </div>
                                                </div>
                                                <div className="d-flex pb-2 justify-content-between">
                                                  <div className="text-black bolder">
                                                    Total Payable :
                                                  </div>
                                                  {" "}
                                                  <div className="text-black bolder">
                                                    {(parseFloat(sendingAmount) + (parseFloat(paymentServiceCharge) + parseFloat(selectedDeliveryMethod.charge))).toFixed(2)} {selectedReceivingCountry.currency}
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                            <div
                                              className={`tick ${
                                                selectedPaymentMethod.id === PaymentMethod?.id
                                                  ? "d-block"
                                                  : "d-none"
                                              }`}
                                            >
                                              <img
                                                src={tickIcon}
                                                className="img-fluid"
                                                alt=""
                                              />
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <hr className="py-1 pe-2 ps-2"></hr>
                                    </div>
                                  );
                                }
                              })}
                          </Modal.Body>
                          <Modal.Footer>
                            <Button
                              className="mt-2 col-lg-3 d-block m-auto nextBtn1"
                              variant="primary"
                              onClick={handleClosePaymentPopup}
                            >
                              Select
                            </Button>
                          </Modal.Footer>
                        </Modal>
                        {/* ////////////////////// */}

                      </div>

                      <div
                        id={`secondStep`} style={{display: "none"}}
                      >
                        <Container>
                          <Row className="respoChildFooter">
                            <Col className="col-lg-12">
                              <h1 className="purpleText bolder text-center mt-3 pt-3 pb-3">
                                Send Money
                              </h1>
                            </Col>
                            <Col className="col-lg-12 flex-column m-auto d-flex justify-content-center text-center ">
                              <div className="smd pb-3">
                                <div className="responsiveFontLarge  text-black text-center ">
                                  Please choose an existing recipient or add a new recipient.
                                </div>
                              </div>
                            </Col>
                          </Row>
                          <Row className="">
                            <Col className="col-lg-12 d-flex mt-3 justify-content-end ms-auto">
                              <Button
                                className="mt-2 col-lg-4 ps-2 pe-2 d-block fullRoundedCorner justify-content-end rounded-5 nextBtn1"
                                variant="primary"
                                onClick={onOpenReceiverPopup}
                              >
                                <img src={plus} className="img-fluid" alt=""/> ADD RECIPIENT
                              </Button>
                            </Col>
                          </Row>

                          <Container>
                            {receivers.length === 0 ? (
                              <Row className="text-center mt-5">
                                <div className="mt-5">
                                  <h5>No Recipient Available.</h5>
                                </div>
                              </Row>
                            ) : (
                              <>
                                <Row className="scrollStep2Main">
                                  {/* /////////////“Receiver////////////// */}
                                  {receivers &&
                                    receivers.map((receiver, index) => {
                                      return (
                                        <div key={index}>
                                          <div
                                            className={`${
                                              selectedReceiver === receiver.id ? "activeCardR p-3" : ""
                                            }  mt-3 p-3`}
                                            onClick={(e) => {
                                              onReceiverSelect(
                                                receiver.id,
                                                receiver.deliveryMethodId
                                              )
                                            }}
                                          >
                                            <div className="row ">
                                              <div className="d-flex justify-content-between">
                                                <div className="1 bolder text-black">
                                                  {receiver.firstName +
                                                    " " +
                                                    receiver.lastName}
                                                  {receiver.businessName}
                                                  {receiver.fullName == "[]"
                                                    ? ""
                                                    : receiver.fullName
                                                      .replace("[", "")
                                                      .replace("]", "")
                                                      .replace(/,/g, " , ")}
                                                </div>
                                                <div className="1 cursor-pointer d-flex">
                                                  <img
                                                    src={editBtn}
                                                    alt=""
                                                    className={`img-fluid pointer ${
                                                      selectedReceiver === index
                                                        ? "d-block"
                                                        : "d-none"
                                                    }`}
                                                    onClick={() => {
                                                      onReceiverUpdate(receiver.id);
                                                    }}
                                                  />

                                                  <img
                                                    src={deleteBtn}
                                                    alt=""
                                                    className={`img-fluid pointer ps-2 ${
                                                      selectedReceiver === index
                                                        ? "d-block"
                                                        : "d-none"
                                                    }`}
                                                    onClick={(e) => {
                                                      onReceiverDelete(
                                                        e,
                                                        receiver.id
                                                      );
                                                    }}
                                                  />
                                                </div>
                                              </div>
                                              <div
                                                className={`${receiver.phone === 0 ? "d-none" : "d-block"} d-flex justify-content-between mt-2`}>
                                                <div className={`1 text-black `}>
                                                  {receiver.phone == 0
                                                    ? "-"
                                                    : receiver.phone}
                                                </div>
                                              </div>
                                              <div className="d-flex justify-content-between mt-2">
                                                <div className="1 text-black">
                                                  {receiver.deliveryMethodName == ""
                                                    ? ""
                                                    : receiver.deliveryMethodName}
                                                </div>
                                              </div>
                                              <div className="d-flex justify-content-between mt-2">
                                                <div className="1 text-black">
                                                  {receiver.bankName == "" ||
                                                  receiver.bankName == undefined
                                                    ? ""
                                                    : receiver.bankName}
                                                </div>
                                              </div>
                                              <div
                                                className={`d-flex justify-content-between mt-2 ${
                                                  selectedReceiver === index ? "d-block" : "d-none"
                                                }`}
                                              >
                                                <div className={`1 text-black `}>
                                                  {receiver.bankAccNo !== ""
                                                    ? receiver.bankAccNo
                                                    : receiver.walletNo}
                                                </div>
                                              </div>
                                              <div
                                                className={`d-flex justify-content-between mt-2 ${
                                                  selectedReceiver === index ? "d-block" : "d-none"
                                                }`}
                                              >
                                                <div className="1 text-black">
                                                  {receiver.relationName}
                                                </div>
                                                <div className="1 textGray">
                                                  {countries.map((item) => {
                                                    if (item.id === receivers[0].countryId) {
                                                      return item.name;
                                                    }
                                                  })}
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <hr className="py-1 mt-4"></hr>
                                        </div>
                                      );
                                    })}
                                </Row>
                                <Row>
                                  <div
                                    className={`btn-component pe-5 ps-5 d-flex justify-content-center} `}
                                  >
                                    <input
                                      className={`col-lg-3 uppercase  nextButton1 ${
                                        receivers.length === 0 ? "d-none" : "d-block"
                                      }`}
                                      type="button"
                                      value={"Next"}
                                    />
                                  </div>
                                </Row>
                              </>
                            )}
                          </Container>

                          <div className="btn-component pe-5 ps-5 d-flex justify-content-center gap-3">
                            <input
                              className={`col-lg-3 uppercase backButton ${backDisplay ? 'block' : 'd-none'}`}
                              type="button"
                              value="BACK"
                              onClick={onBackClick}
                            />

                            <input
                              onClick={onNextClick}
                              className="col-lg-3 uppercase  nextButtonStep1"
                              type="button"
                              value="NEXT"
                            />
                          </div>
                        </Container>

                        {/* Receiver Add popup */}

                        <Modal
                          show={receiverAddPopup}
                          onHide={(e) => {
                            handleCloseReceiverAddPopup()
                          }}
                          size="lg"
                        >
                          {receiverFormLoading ? <Loader/> : <Row className="d-block">
                            <Modal.Header className="text-center  mt-1">
                              <Modal.Title className="d-flex m-auto">
                                <h1 className="purpleText bolder responsiveFontLargeHeading  mb-0">
                                  Add Recipient
                                </h1>
                              </Modal.Title>
                            </Modal.Header>
                            <Modal.Body className="mainss">
                              <Row>
                                <Col className="col-lg-12 mt-2 text-center">
                                  <label className="text-black text-center">
                                    Recipient Bank Details
                                  </label>
                                  <Row className="">
                                    <Col className="">
                                      <Form>
                                        <Form
                                          className="mt-3 sendMoneyPaddingForm"
                                          id="Signup_Step1"
                                        >
                                          <Row className="">
                                            <Form.Group
                                              as={Col}
                                              className="left-inner-addon input-container required text-start"
                                            >
                                              <i className="purpleText main fa fa-user "></i>
                                              <Form.Control
                                                type="text"
                                                required
                                                placeholder="First Name"
                                                name="UIndidual_Firstname"
                                                className="formControlStep2 reflink link form-input"
                                              />
                                              <label for="name" className="form-label1">
                                                First Name
                                              </label>
                                              <div className="responsiveFontLarge  d-none text-danger error_message ms-2 error">
                                                First Name
                                              </div>

                                            </Form.Group>

                                            <Form.Group
                                              as={Col}
                                              className="left-inner-addon input-container required text-start"
                                            >
                                              <i className="purpleText main fa fa-user "></i>
                                              <Form.Control
                                                type="text"
                                                required
                                                placeholder="Middle Name"
                                                name="UIndidual_Middlename"
                                                className="formControlStep2 reflink link form-input"
                                              />
                                              <label for="name" className="form-label1">
                                                Middle Name
                                              </label>
                                              <div className="responsiveFontLarge  d-none text-danger error_message ms-2 error">
                                                Middle Name
                                              </div>

                                            </Form.Group>

                                            <Form.Group
                                              as={Col}
                                              className="left-inner-addon input-container required text-start"
                                            >
                                              <i className="purpleText main fa fa-user "></i>
                                              <Form.Control
                                                type="text"
                                                required
                                                placeholder="Last Name"
                                                name="UIndidual_Lastname"
                                                className="formControlStep2 reflink link form-input"
                                              />
                                              <label for="name" className="form-label1">
                                                Last Name
                                              </label>
                                              <div className="responsiveFontLarge  d-none text-danger error_message ms-2 error">
                                                Last Name
                                              </div>

                                              {/* {errors.name && <p>{errors.name}</p>} */}
                                            </Form.Group>
                                          </Row>
                                          { inputDetails && inputDetails.length ?
                                            inputDetails.map(inputDetail => {
                                              if (inputDetail.dropDownOptions && inputDetail.dropDownOptions.length > 0) {
                                                return (
                                                  <Row>
                                                    <Form.Group
                                                      as={Col}
                                                      className="left-inner-addon input-container required text-start"
                                                    >
                                                      <Select
                                                        id="state"
                                                        options={inputDetail.dropDownOptions.map(option => {
                                                          return {
                                                            value: option.value,
                                                            label: option.text
                                                          }
                                                        })}
                                                        placeholder={`select ${inputDetail.label}`}
                                                        className="responsiveFontLarge SelectValueSelect nationality SelectValueSelect1 SelectHoverLabel required pointer"
                                                        closeMenuOnSelect={true}
                                                        isSearchable={true}
                                                        onChange={onStateChange}
                                                      />
                                                    </Form.Group>
                                                  </Row>
                                                )
                                              } else {
                                                return (
                                                  <Row className="">
                                                    <Form.Group
                                                      as={Col}
                                                      className="left-inner-addon input-container required text-start"
                                                    >
                                                      <i className="purpleText main fa fa-map "></i>
                                                      <Form.Control
                                                        type="text"
                                                        required
                                                        placeholder={inputDetail.label}
                                                        name="Address"
                                                        className="formControlStep2 reflink link form-input"
                                                      />
                                                      <label for={inputDetail.label} className="form-label1">
                                                        {inputDetail.label}
                                                      </label>
                                                      <div className="responsiveFontLarge  d-none text-danger error_message ms-2 error">
                                                        {inputDetail.label}
                                                      </div>

                                                      {/* {errors.name && <p>{errors.name}</p>} */}
                                                    </Form.Group>
                                                  </Row>
                                                )
                                              }
                                            })
                                            : ''}
                                        </Form>
                                      </Form>
                                    </Col>
                                  </Row>
                                </Col>
                              </Row>
                              <Row>
                                <Col className="col-lg-12">
                                  <Button
                                    className="mt-0 my-4 col-lg-3 d-block m-auto nextBtn1"
                                    // onClick={handleShowRec6}
                                    onClick={submitReceiver}
                                    variant="primary"
                                  >
                                    Save
                                  </Button>
                                </Col>
                              </Row>
                            </Modal.Body>
                          </Row> }

                        </Modal>
                        {/* /////////receiver Type end/////// */}

                      </div>

                      <div id="thirdStep" style={{display: "none"}}>
                        <Container>
                          <Row className="respoChildFooter">
                            <Col className="col-lg-12">
                              <h1 className="purpleText bolder text-center mt-3 pt-3 pb-3">
                                Send Money
                              </h1>
                            </Col>
                            <Col className="col-lg-12 flex-column m-auto d-flex justify-content-center text-center ">
                              <div className="smd pb-3">
                              </div>
                            </Col>
                          </Row>

                          <Row className="">
                            <Modal.Body className="mainss">
                                  <Row>
                                    <Col className="col-lg-12 mt-2 text-center mt-3">
                                          <div className="d-flex flex-column ">
                                            <img
                                              height="100"
                                              width="100"
                                              src={firstPay}
                                              alt=""
                                              className="img-fluid my-2 d-block m-auto"
                                            />
                                            <div
                                              className="responsiveFontLarge  text-black text-center w-75 d-block m-auto my-2">
                                              Set up PayTO agreement to Pay directly from your
                                              bank account. Use PayID or BSB and account number.
                                            </div>
                                          </div>

                                      {showPaytoAgreementDetail ?
                                        <section className="container ">
                                        <Form>
                                          <Form.Group
                                            className="mb-3 text-start "
                                            controlId="formBasicEmail"
                                          >
                                            <Form.Label className="ms-2 text-black">
                                              Payee
                                            </Form.Label>
                                            <Form.Control
                                              disabled
                                              type="text"
                                            />
                                          </Form.Group>
                                          <Form.Group
                                            className="mb-3 text-start text-black"
                                            controlId="formBasicEmail"
                                          >
                                            <Form.Label className="ms-2 text-black">
                                              Description
                                            </Form.Label>
                                            <Form.Control
                                              disabled
                                              type="text"
                                              value={'Agreement on behalf of Legal Remit'}
                                            />
                                          </Form.Group>
                                          <Form.Group
                                            className="mb-3 text-start text-black"
                                            controlId="formBasicEmail"
                                          >
                                            <Form.Label className="ms-2 text-black">
                                              PAYID
                                            </Form.Label>
                                            <Form.Control
                                              disabled
                                              type="text"
                                              placeholder="PAYID"
                                            />
                                          </Form.Group>
                                          <Form.Group
                                            className="mb-3 text-start text-black"
                                            controlId="formBasicEmail"
                                          >
                                            <Form.Label className="ms-2 text-black">
                                              BSB
                                            </Form.Label>
                                            <Form.Control
                                              disabled
                                              type="text"
                                              placeholder="123457894567"
                                            />
                                          </Form.Group>
                                          <Form.Group
                                            className="mb-3 text-start text-black"
                                            controlId="formBasicEmail"
                                          >
                                            <Form.Label className="ms-2 text-black">
                                              Acc No.
                                            </Form.Label>
                                            <Form.Control
                                              disabled
                                              type="text"
                                              placeholder="Account No"
                                            />
                                          </Form.Group>
                                          <Form.Group
                                            className="mb-3 text-start text-black"
                                            controlId="formBasicEmail"
                                          >
                                            <Form.Label className="ms-2 text-black">
                                              Amount
                                            </Form.Label>
                                            <Form.Control
                                              disabled
                                              type="text"
                                            />
                                          </Form.Group>
                                          <Form.Group
                                            className="mb-3 text-start text-black"
                                            controlId="formBasicEmail"
                                          >
                                            <Form.Label className="ms-2 text-black">
                                              Frequency
                                            </Form.Label>
                                            <Form.Control
                                              disabled
                                              type="text"
                                              placeholder="Frequency"
                                              value={'Ad-hoc'}
                                            />
                                          </Form.Group>
                                          <Form.Group
                                            className="mb-3 text-start text-black"
                                            controlId="formBasicEmail"
                                          >
                                            <Form.Label className="ms-2 text-black">
                                              Start Date
                                            </Form.Label>
                                            <Form.Control
                                              disabled
                                              type="text"
                                              placeholder={'Start Date'}
                                            />
                                          </Form.Group>
                                          <Form.Group
                                            className="mb-3 text-start text-black"
                                            controlId="formBasicEmail"
                                          >
                                            <Form.Label className="ms-2 text-black">
                                              Auto Renewal
                                            </Form.Label>
                                            <Form.Control
                                              disabled
                                              type="text"
                                              placeholder="Auto Renewal"
                                              value={'No'}
                                            />
                                          </Form.Group>
                                        </Form>
                                        <Row>
                                          <Col className="col-lg-12">
                                            <Button
                                              className="my-4 col-lg-3 d-block m-auto nextBtn1"
                                              variant="primary"
                                            >
                                              Pay now ({ sendingAmount } { selectedReceivingCountry.currency })
                                            </Button>
                                          </Col>
                                        </Row>
                                      </section> :
                                        <Form className="mt-3 pe-1 ps-1" id="Signup_Step1">
                                        <Row>
                                          <div
                                            className={` justify-content-end d-flex pe-4`}>
                                            <p className={`mycardbtn bolder pointer nextBtn1 px-3 text-white`}
                                               onClick={(e) => {
                                               }}>
                                              Saved PayTo Details
                                            </p>
                                          </div>
                                        </Row>
                                        <Row className="respoChildFooter">
                                          <Form.Group
                                            as={Col}
                                            className="left-inner-addons input-container required"
                                          >
                                            <img
                                              src={thirdPay}
                                              className="img-fluid aaa"
                                              alt=""
                                            />
                                            <Form.Control
                                              type="text"
                                              required
                                              placeholder="PayID"
                                              name="PayTo"
                                              className=" py-4 formControlStep2 reflink  link"
                                            />
                                            <div
                                              className="responsiveFontLarge  d-none text-danger error_message ms-2 error">
                                              PayID
                                            </div>
                                            <div
                                              className="responsiveFontLarge error_message text-danger text-start ms-2">
                                              please Enter PayId
                                            </div>
                                            <div
                                              className="responsiveFontLarge error_message text-danger text-start ms-2">
                                              please Enter Valid PayId
                                            </div>
                                          </Form.Group>
                                        </Row>
                                        <div className="responsiveFontLarge  text-center my-4">
                                          OR
                                        </div>
                                        <Row className="mt-3">
                                          <Form.Group
                                            as={Col}
                                            className="left-inner-addon input-container required"
                                          >
                                            <Form.Control
                                              type="text"
                                              required
                                              placeholder="BSB"
                                              name="BSB"
                                              className={`py-4 formControlStep2 reflink link`}
                                            />
                                            <div
                                              className="responsiveFontLarge  d-none text-danger error_message ms-2 error">
                                              BSB
                                            </div>

                                            <div
                                              className="responsiveFontLarge error_message text-danger text-start ms-2">
                                              please Enter BSB
                                            </div>

                                          </Form.Group>
                                        </Row>
                                        <Row className="mt-3">
                                          <Form.Group
                                            as={Col}
                                            className="left-inner-addon input-container required"
                                          >
                                            <Form.Control
                                              type="text"
                                              required
                                              placeholder="Account No."
                                              name="Account_No"
                                              className="py-4 formControlStep2 reflink link"

                                            />
                                            <div
                                              className="responsiveFontLarge  d-none text-danger error_message ms-2 error">
                                              Account No.
                                            </div>
                                            <div
                                              className="responsiveFontLarge error_message text-danger text-start ms-2">
                                              please Enter Account No.
                                            </div>

                                            {/* {errors.name && <p>{errors.name}</p>} */}
                                          </Form.Group>
                                        </Row>

                                        <Row className="mt-3 d-flex">
                                          <div className="d-flex align-items-center">
                                            <input
                                              type="checkbox"
                                              id="vehicle1"
                                              className="w-auto "
                                              name="vehicle1"
                                              value="Bike"
                                            />
                                            <label htmlFor="vehicle1" className="ms-2 mb-0">
                                              {' '}
                                              Save this as preferred payment method
                                            </label>
                                          </div>
                                        </Row>
                                        {/* </Form> */}
                                        <Row>
                                          <Col className="col-lg-12">
                                            <Button
                                              className="my-4 col-lg-3 d-block m-auto nextBtn1"
                                              variant="primary"
                                              onClick={validatePaytoAgreement}
                                            >
                                              Next
                                            </Button>
                                          </Col>
                                        </Row>
                                      </Form>
                                      }
                                    </Col>
                                  </Row>
                            </Modal.Body>
                          </Row>
                        </Container>
                      </div>

                      <div id="fourthStep" style={{display: "none"}}>
                      </div>

                    </div>
                  </userContext.Provider>
                  <div
                    className={`btn-component pe-5 ps-5 d-flex justify-content-center"`}
                    onClick={onNextClick}
                  >
                  </div>

                  <div
                    className={`btn-component pe-5 ps-5 d-flex justify-content-center"`}
                    onClick={onNextClick}
                  >
                  </div>
                </div>
              </Row>
            </div>
          </div>
        </Container>}
        <Footer></Footer>
      </secton>
    </>
  )
}