import React from 'react'

export default function MobileRedirect() {

    return (
    <div>
      Mobile Redirection Testing Purpose
    </div>
    );
}
