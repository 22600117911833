import React, { useEffect, useState, useRef } from "react";

import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

import Image from "react-bootstrap/Image";
import one from "../../../assets/images/BuyWithCard.svg";
import two from "../../../assets/images/InitiateMoneyTransfer.svg";
import three from "../../../assets/images/BoardingPass.svg";
import cashWithdraw from "../../../assets/images/bi_cash-stack.png";
import bankdesposit from "../../../assets/images/Bankdeposit.png";

import wallet from "../../../assets/images/wallet.png";

import cashpickup from "../../../assets/images/cashpickup.png";

import "../../../assets/assets/scss/pages/Utility.scss";
import { Row } from "react-bootstrap";

const Utilty = () => {
  const owlCarouselRef = useRef(null);

  
  const [maxHeight, setMaxHeight] = useState(0);
  const [currentIndex, setCurrentIndex] = useState(0); 

  
  const options = {
    loop: true,
    center: true,
    items: 3,
    margin: 0,
    autoplay: true,
    dots: false,
    autoplayTimeout: 4000,
    smartSpeed: 450,
    nav: false,
    responsive: {
      0: {
        items: 1,
      },
      600: {
        items: 3,
      },
      1000: {
        items: 3,
      },
    },
  };

  const setCardHeights = () => {
    const cards = document.querySelectorAll(".carousel-card");
    if (cards.length > 0) {
      let maxCardHeight =500; 
      cards.forEach((card) => {
        card.style.height = "auto"; 
        const cardHeight = card.clientHeight;
        if (cardHeight > maxCardHeight) {
          maxCardHeight = cardHeight;
        }
      });
      cards.forEach((card) => {
        card.style.height = `${maxCardHeight}px`; 
      });
    }
  };

  const handleDrag = (event) => {
    const clickedIndex = event.item.index;

    setCurrentIndex(clickedIndex);


    const owlInstance = owlCarouselRef.current;
    if (owlInstance) {
      owlInstance.to(clickedIndex, 300); 
    }

    setCardHeights();
  };

  useEffect(() => {

    const carousel = document.getElementById("customer-testimonoals");
    if (carousel) {
      const owlInstance = window.jQuery(carousel).data("owlCarousel");
      if (owlInstance) {
        owlInstance.on("changed.owl.carousel", () => {
          setCardHeights();
        });
      }
    }

    const owlInstance = owlCarouselRef.current;
    if (owlInstance) {
      owlInstance.onDrag = handleDrag;

  
      setCardHeights();

      return () => {
        owlInstance.onDrag = null; 
      };
    }

    
    setCardHeights();
  }, []); 





  return (
    <>

    
      <section
        id="testimonial"
        className=" responsiveMainForMobile utilityMain testimonials pt-70 pb-70"
      >
        <div className="container mt-5">

          <div className="row">
            <div className="col-md-12">
            <h2 className=" text-center responsiveFontLargeHeading abtBefore purpleText bolder pb-0">
           Services
           </h2>

           
              <OwlCarousel
                id="customer-testimonoals"
                className="owl-carousel owl-theme"
                {...options}
                ref={owlCarouselRef} 
              >
                <div
                  className={`item ${currentIndex === 1 ? "highlighted" : ""}`}
                >
                  <div className="carousel-card shadow-effect">
                    <img
                      className="img-circle"
                      src={two}
                      alt="Travel/Ticketing"
                    />
                    <p className="medium">Remittance</p>
                    <small className="text-gray mt-3 text-justify d-block d-block">
                      Effortless Remittance Services with Legal Remit!   <small className="text-gray mt-2 text-justify d-block d-block">
                      Legal Remit offers seamless and secure remittance services to
                      help you send money globally with ease. Whether you need
                      to support your loved ones, make business transactions, or
                      manage international payments, we've got you covered. With
                      our trusted platform, you can transfer funds quickly,
                      reliably, and at competitive exchange rates. Experience
                      hassle-free remittance with Legal Remit and enjoy the
                      peace of mind that comes with secure and efficient money
                      transfers.
                      </small>
                    </small>
                  </div>
                </div>
                <div
                  className={`item ${currentIndex === 8 ? "highlighted" : ""}`}
                >
                 <div
                    className="carousel-card shadow-effect"
                    style={{ height: maxHeight }}
                  >
                    <i className="fa fa-graduation-cap img-fluid gradCap purpleText w-25 mb-3"/>
                    {/* <img
                      className="img-circle"
                      src={one}
                      alt="Utility Bill Payment"
                    /> */}
                    <p className="medium">Education and Migration Service</p>
                    <small className="text-gray mt-3 text-justify d-block  d-block">
                      Our sister company, Focus Visa and Education Services,
                      offers a comprehensive range of education and migration
                      services. With expertise in education, migration, visa
                      processing, insurance, and other consultation areas, Focus
                      Visa is committed to delivering exceptional service at
                      unbeatable prices. We strive to provide unparalleled
                      assistance in the industry.
                    </small>{" "}
                    <small className="text-gray mt-3 text-justify d-block d-block">
                    Experience top-notch education and migration services through our sister company, Focus Visa and Education Services. Specializing in comprehensive consultancy for education, migration, visa, insurance, and more, Focus Visa is committed to delivering unparalleled service at unbeatable rates. Trust in us to provide you with exceptional guidance and support throughout your journey.
                    </small>
            
                  </div>
                </div>

                <div
                  className={`item ${currentIndex === 5 ? "highlighted" : ""}`}
                >
                  <div
                    className="carousel-card shadow-effect"
                    style={{ height: maxHeight }}
                  >
                    <img className="img-circle" src={bankdesposit} alt="Remittance" />
                    <p className="medium">Bank Deposit/ Account Deposit</p>
                    <small className="text-gray mt-3 text-justify d-block d-block">
                      Deposit Funds into Your Bank Account with Legal Remit!
                      <small className="text-gray mt-2 text-justify d-block d-block">
                      Legal Remit makes it simple and convenient to deposit
                      funds into your bank account securely and efficiently. Say
                      goodbye to limited banking hours and waiting in line. With
                      our trusted platform, you can deposit money into your bank
                      account 24/7, even on weekends and holidays. Experience
                      the freedom of instant account deposits with Legal Remit
                      and enjoy the flexibility and convenience that comes with
                      managing your finances on your own terms.
                      </small>
                    </small>
                  </div>
                </div>

                <div
                  className={`item ${currentIndex === 3 ? "highlighted" : ""}`}
                >
                  <div
                    className="carousel-card shadow-effect"
                    style={{ height: maxHeight }}
                  >
                    <img
                      className="img-circle"
                      src={cashWithdraw}
                      alt="Utility Bill Payment"
                    />
                    <p className="medium">Cash Pickup</p>
                    <small className="text-gray mt- text-justify d-block d-block">
                      Convenient Cash Pickup Services with Legal Remit!  
                      <small className="text-gray mt-2 text-justify d-block d-block">Legal
                      Remit offers hassle-free cash pickup services, providing
                      you with a convenient way to receive money quickly and
                      securely. Whether you're receiving funds from a loved one,
                      a business transaction, or any other purpose, our trusted
                      platform ensures a smooth and reliable cash pickup
                      experience. Simply choose a convenient cash pickup
                      location near you, and with Legal Remit, your funds will
                      be ready for pickup in no time.
                      </small>
                    </small>
                  </div>
                </div>
                <div
                  className={`item ${currentIndex === 4 ? "highlighted" : ""}`}
                >
                  <div
                    className="carousel-card shadow-effect"
                    style={{ height: maxHeight }}
                  >
                    <img
                      className="img-circle"
                      src={wallet}
                      alt="Utility Bill Payment"
                    />
                    <p className="medium">Wallet Deposit</p>
                    <small className="text-gray mt-3 text-justify d-block d-block">
                      Easy Wallet Deposit with Legal Remit! 
                      
                      <small className="text-gray mt-2 text-justify d-block d-block">
                      Legal Remit simplifies the process of depositing funds into your
                      digital wallet. Whether you use popular e-wallets or
                      mobile payment apps, our platform enables you to
                      conveniently top up your wallet with just a few clicks.
                      Say goodbye to the hassle of carrying cash or visiting
                      physical locations. With Legal Remit, you can securely
                      deposit funds into your wallet anytime, anywhere, ensuring
                      that you have easy access to digital payments whenever you
                      need them.
                        </small>
                    </small>
                  </div>
                </div>
                <div
                  className={`item ${currentIndex === 2 ? "highlighted" : ""}`}
                >
                     <div
                    className="carousel-card shadow-effect"
                    style={{ height: maxHeight }}
                  >
                    <img
                      className="img-circle"
                      src={three}
                      alt="Utility Bill Payment"
                    />
                    <p className="medium">Travel and Ticketing </p>
                    <small className="text-gray mt-3 text-justify d-block d-block">
                      Fly Lumbini offers the best deals in the market for flight
                      tickets, hotel accommodation, car rentals, holiday
                      packages, and all travel services. With unbeatable prices
                      and exceptional service, Fly Lumbini is a comprehensive
                      solution that caters to all your travel needs.
                    </small>
                  </div>
                
                </div>
                <div
                  className={`item ${currentIndex === 6 ? "highlighted" : ""}`}
                >
                  <div
                    className="carousel-card shadow-effect"
                    style={{ height: maxHeight }}
                  >
                    <img
                      className="img-circle"
                      src={bankdesposit}
                      alt="Utility Bill Payment"
                    />
                    <p className="medium">Bank Account in Nepal</p>
                    <small className="text-gray mt-3 text-justify d-block d-block">
                      Open a bank account with Everest Bank Nepal through Legal
                      Remit effortlessly. Just click on the link and take your
                      first step towards saving for the future. With the best
                      interest rates and unparalleled customer service, Everest
                      Bank is the ultimate choice for all customers' banking
                      needs.
                    </small>
                  </div>
                </div>
                <div
                  className={`item ${currentIndex === 7 ? "highlighted" : ""}`}
                >
                  <div
                    className="carousel-card shadow-effect"
                    style={{ height: maxHeight }} 
                  >
                    <img
                      className="img-circle"
                      src={one}
                      alt="Utility Bill Payment"
                    />
                    <p className="medium">Utility Bill Payment</p>

                    <small className="text-gray mt-3 text-justify d-block d-block ">
                      Pay for Your Utility Bills in Nepal with Legal Remit!
                      <small className="text-gray mt-2 text-justify d-block d-block">
                      Managing your utility bills has never been easier. With
                      Legal Remit, you can conveniently pay your electricity,
                      water, internet, and other utility bills in Nepal. Say
                      goodbye to long queues and time-consuming processes. Our
                      user-friendly platform ensures hassle-free bill payments
                      in just a few clicks. Experience the convenience and peace
                      of mind of managing your bills with Legal Remit. Simplify
                      your life today!
                      </small>
                    </small>

                  
                  </div>
                </div>
              </OwlCarousel>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Utilty;
