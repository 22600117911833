import React, { useState, useEffect } from "react";
import Footer from "../../../Home/Footer/Footer";
import NavBar from "../../../Home/Navbar/Navbar";
import {
  Container,
  Image,
  Form,
  Row,
  Col,
  Button,
  Modal,
} from "react-bootstrap";
import LoginAvatar from "../../../../assets/images/LoginAvatar.svg";
import ReCAPTCHA from "react-google-recaptcha";
import { CommonConstants } from "../../../../Constants/common.constants";
import axios from "axios";
import "../../../../assets/assets/scss/pages/ForgotPassword.scss";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { Circles } from "react-loader-spinner";

import * as Yup from "yup";
import Loader from "../../../Loader/Loader";

export default function ForgotPassword({ children, props }) {
  // const navigate = useNavigate()
  const history = useHistory();
  const [loaderr, setLoader] = useState(false);

  useEffect(() => {
    // // console.log(ShowNameZone,"this is comment")
    // // console.log(Successhandle)
  }, []);

  // const [steps, setSteps] = useState([
  //   { key: 'firstStep', label: '', icon:'first' ,isDone: true, isRemain : true , component: firstComponent },
  //   { key: 'secondStep', label: '',icon:'second', isDone: false,isRemain : false , component: secondComponent },
  //   { key: 'thirdStep', label: '', icon:'third', isDone: false, isRemain : false , component: thirdComponent },
  //   { key: 'finalStep', label: '', icon:'fourth', isDone: false,isRemain : false , component: finalComponent },
  // ]);

  // const [activeStep, setActiveStep] = useState(steps[0]);

  // const handleNext = async () => {

  //   if (steps[steps.length - 1].key === activeStep.key) {
  //     alert('You have completed all steps.');
  //     return;
  //   }
  //   const index = steps.findIndex(x => x.key === activeStep.key);
  //   setSteps(prevStep => prevStep.map(x => {
  //     if (x.key === steps[index + 1].key)
  //     {x.isDone = true; x.isRemain = true}
  //     else {
  //       if(x.isDone === true)
  //       {
  //         x.isDone = true; x.isRemain = true
  //       }
  //       else {
  //         x.isDone = false; x.isRemain = false
  //       }
  //     }
  //     return x;
  //   }))
  //   setActiveStep(steps[index + 1]);
  // }

  // const handleBack = () => {

  //   const index = steps.findIndex(x => x.key === activeStep.key);
  //   if (index === 0) return;

  //   setSteps(prevStep => prevStep.map((x,i) => {

  //     if (x.key === activeStep.key)
  //     {x.isDone = false; x.isRemain = false}
  //     else {
  //       if(i <  index)
  //       {
  //         x.isDone = true; x.isRemain = true
  //       }
  //       else {
  //         x.isDone = false; x.isRemain = false
  //       }
  //     }
  //     return x;
  //   }))
  //   setActiveStep(steps[index - 1]);
  // }

  const handleSignUp = () => {
    // navigate('/signup', {replace: true})
    window.scrollTo(0, 0);
  };

  const [Successhandle, setSuccesshandle] = useState(null);
  const [Errorhandle, setErrorhandle] = useState(null);

  const [showSuccess, setshowSuccess] = useState(false);
  const [showError, setshowError] = useState(false);

  const handleSuccessClose = () => {
    setshowSuccess(false);
    // history.push('/')
  };
  const handleSuccessShow = () => setshowSuccess(true);

  const handleErrorClose = () => setshowError(false);
  const handleErrorShow = () => setshowError(true);

  // const onSubmit = async (values) => {
  //   try {

  //     const { ...data } = values;
  //     // console.log(values)
  //     const response = await axios.post(CommonConstants.BASE_URL + "/api/signin", data);
  //     // console.log(response)/////login na responce ma email mangavo padse localstorage ma save karava mate /////
  //     if (response.data.status === true) {
  //       // console.log(response.data.message)
  //       // setShowNameZone(true)
  //       localStorage.setItem('loginkey', "true");
  //       history.push("/")
  //       setSuccesshandle(response.data.message)
  //       handleSuccessShow()
  //     }
  //     else if (response.data.status === "error") {
  //       // console.log(response.data.message)
  //       setErrorhandle(response.data.message)
  //       handleErrorShow()
  //     }
  //   }
  //   catch (err) {
  //     // console.log(err)
  //   }
  // };

  const [popupsetup, setpopupsetup] = useState(false);
  const [UserId, setuserId] = useState();

  const onSubmit = async (values) => {
    try {
      setLoader(true);
      const { ...data } = values;
      // console.log(values,"changes email")
      const response = await axios.post(
        CommonConstants.BASE_URL + "/forgotpassword",
        values
      );
      // console.log(response,"email")/////login na responce ma email mangavo padse localstorage ma save karava mate /////
      if (response.data.status === true) {
        // console.log(response.data.message)
        setSuccesshandle(response.data.message);
        // setuserId(response.data.data.id)
        localStorage.setItem("userid", response.data.data.id);
        setpopupsetup(true);
        handleSuccessShow();
        setLoader(false);
      } else if (response.data.status === false) {
        // console.log(response.data.message)
        // setErrorhandle(response.data.message)
        setSuccesshandle(response.data.message);
        handleSuccessShow();
        setpopupsetup(false);
        setLoader(false);
      }
    } catch (err) {
      // console.log(err)
    }
  };
  // // console.log(UserId)
  const formik = useFormik({
    initialValues: {
      email: "",
      // password: ""
    },

    validationSchema: Yup.object({
      email: Yup.string()
        .email("Please enter a valid email id")
        .required("Please enter email id"),

      // password: Yup.string()
      //   .min(6, "Must be 6 characters or more")
      //   .matches(/[a-z]+/, "One lowercase character")
      //   .matches(/[A-Z]+/, "One uppercase character")
      //   .matches(/[@$!%*#?&]+/, "One special character")
      //   .matches(/\d+/, "One number")
      //   .required("Please enter password"),
    }),

    onSubmit,
  });

  const [verfied, setverfied] = useState(false);

  const onChange = (value) => {
    // console.log("Captcha value:", value);
    setverfied(true);
  };

  const ResetPassword = () => {
    window.scrollTo(0, 0);
    history.push("/resetpassword");
  };

  return (
    <>
      {loaderr == true ? <Loader /> : ""}
      <section className="mainLoginSection loginpagepadding">
        <NavBar></NavBar>
        <Container>
          <div className="mainLoginBox mt-5 ">
            <div className="box row d-flex">
              <div className="col-lg-5 firstBlock ">
                <div className=" justify-content-center justify-items-center d-flex my-5 mt-6 m-auto">
                  {/* <h1>first side</h1> */}
                  <Image src={LoginAvatar} className="loginavatar"></Image>
                </div>
              </div>
              <div className="col-lg-7 loginPadding g-0">
                <div className="bgSkyBlue  text-white text-center bolder my-5 py-3">
                  Welcome to LegalRemit
                </div>

                <div className="mx-3 login">
                  <div className="px-4">
                    <Row className="text-center">
                      <h1 className="Loginheading bolder">Forgot Password</h1>
                    </Row>
                    <Row className="text-center mb-3">
                      <p className="fs-6 mb-3 simple">
                        No worries, we will send you reset instructions
                      </p>
                    </Row>

                    <Form onSubmit={formik.handleSubmit}>
                      <Row className="mb-3">
                        <Form.Group
                          as={Col}
                          className="left-inner-addon input-container"
                        >
                          <i className="logincolor main fa fa-envelope"></i>
                          <Form.Control
                            type="email"
                            placeholder="Enter Email / phone"
                            onBlur={formik.handleBlur}
                            className="loginformcontrol"
                            name="email"
                            value={formik.values.email}
                            onChange={formik.handleChange}
                          />
                          {formik.errors.email && formik.touched.email && (
                            <small className="responsiveFontLarge  validation-msg medium ms-3">
                              {formik.errors.email}
                            </small>
                          )}
                        </Form.Group>
                      </Row>

                      <Row className="justify-content-center justify-items-center d-flex col-lg-4 m-auto">
                        <Button
                          className=" uppercase ForgotPasswordButton fs-4 "
                          type="submit"
                        >
                          SEND
                        </Button>
                        {/* <Button className='w-25 uppercase ForgotPasswordButton fs-4 ' type="submit" onClick={ResetPassword}>SEND</Button> */}
                      </Row>
                    </Form>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="">
            <Modal
              className=""
              show={showSuccess}
              onHide={handleSuccessClose}
              centered
            >
              <Row className="">
                <Col className="col-lg-12 d-flex align-items-center ">
                  <Col className="col-lg-5 verfiyBlock1 moneySendRespo">
                    <img src={LoginAvatar} className="img-fluid p-5" />
                  </Col>
                  <Col className="col-lg-7 Error_Popup">
                    <Modal.Header
                      className=" borderHeader py-3 "
                      closeButton
                      onHide={handleSuccessClose}
                    >
                      <Modal.Title className="px-0">
                        <small
                          className={` bolder successText fs-3 ${
                            popupsetup === true ? "d-block" : "d-none"
                          }`}
                        >
                          Success
                        </small>
                        <small
                          className={` bolder text-danger radiumText fs-3 ${
                            popupsetup === false ? "d-block" : "d-none"
                          }`}
                        >
                          Error
                        </small>
                      </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <p className="fs-6 py-5 pt-1 pb-1">{Successhandle}</p>
                    </Modal.Body>
                  </Col>
                </Col>
              </Row>
            </Modal>
          </div>
        </Container>
      </section>
      <Footer></Footer>
    </>
  );
}
