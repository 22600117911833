import React, { useState, useEffect } from "react";
import axios from "axios";
import { CommonConstants } from "../../Constants/common.constants";
import NavBar from "../Home/Navbar/Navbar";
import Footer from "../Home/Footer/Footer";
import { Container, Row } from "react-bootstrap";
import parse from 'html-react-parser';
import { useParams } from 'react-router-dom';



export default function DynamicCMSPage({ props }) {
  const [path, setPath] = useState("");
  const [pageDesign, setPageDesign] = useState([]);
  const { id } = useParams();

  if (id !== path) {

    const response = axios.post(
      CommonConstants.BASE_URL + "/getcmsbyslug",
      {
        slug: id,
      }
    ).then((response) => {
      setPageDesign(response.data.data); // Assuming the response data matches the structure you expect
      setPath(id);
    })

  }


  // const fetchPageData = async (slug) => {
  //   try {

  //   } catch (error) {
  //     console.error("Error fetching page data:", error);
  //   }
  // };

  // useEffect(() => {
  //   var res = window.location.pathname;
  //   res = res.split("/");
  //   // setPath(res[2])
  //   getDesignPaths(res[1]);
  //   console.log("id1", res[1]);
  //   console.log("id2", res[2]);
  // }, []);

  // const getDesignPaths = async (path) => {
  //   var abc = path;
  //   var payload = {
  //     id: path,
  //   };

  //   var endPath = await axios.post(
  //     CommonConstants.BASE_URL + "/getcmsbyid",
  //     payload
  //   );
  //   setPageDesign(endPath.data.data);
  //   console.log("end", endPath.data);
  // };

  return (
    <>
      <section className="abtPage">
        <NavBar></NavBar>
        <Container className="bg-white mt-5 rounded-4 mb-5  py-4 px-4">
          <>
            <h2 className="bolder purpleText text-center">
              {pageDesign?.title}
            </h2>

            <p className="text-justify responsiveFontLarge first text-black fs-6  pt-3">
              {typeof pageDesign?.description === 'string' ? parse(pageDesign?.description) : ''}


            </p>
          </>
        </Container>
        <Footer />
      </section>
    </>
  );
}

// {Dynamic code}

// import React, { useEffect, useState } from "react";
// import axios from "axios";
// import "../../../assets/assets/scss/pages/Clients.scss";
// import Container from "react-bootstrap/Container";
// import Col from "react-bootstrap/Col";
// import Image from "react-bootstrap/Image";
// import Row from "react-bootstrap/Row";
// import Card from "react-bootstrap/Card";
// import "../../../assets/assets/scss/pages/Clients.scss";
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";
// import Slider from "react-slick";
// import Button from "react-bootstrap/Button";
// import quat from "../../../assets/images/quatation.svg";
// import user from "../../../assets/images/user.svg";
// import RubberBand from "react-reveal/RubberBand";
// import HeadShake from "react-reveal/HeadShake";
// import StarRatings from "react-star-ratings";

// export default function Clients({ children }) {
//   const [reviews, setReviews] = useState([]);
//   const corsAnywhereUrl = "https://cors-anywhere.herokuapp.com";
//   const apiKey = "AIzaSyCNJRR1zkMpq2RLpT6bM2BLAO2kEDZ8qtA";

//   const options = {
//     dots: false,
//     infinite: true,
//     speed: 500,
//     slidesToShow:2,
//     slidesToScroll: 1,
//     autoplay: false,
//     autoplaySpeed: 3000,
//     responsive: [
//       {
//         breakpoint: 1024,
//         settings: {
//           slidesToShow: 3,
//           slidesToScroll: 3,
//           infinite: true,
//           dots: true
//         }
//       },
//       {
//         breakpoint: 600,
//         settings: {
//           slidesToShow: 2,
//           slidesToScroll: 2,
//           initialSlide: 2
//         }
//       },
//       {
//         breakpoint: 480,
//         settings: {
//           slidesToShow: 1,
//           slidesToScroll: 1
//         }
//       }
//     ],
//     style: {
//       margin: '0 10px',
//     },
//   };

//   const fetchReviews = async () => {
//     try {
//       const response = await axios.get(
//         `${corsAnywhereUrl}/https://maps.googleapis.com/maps/api/place/details/json?place_id=ChIJJS8sGK-vEmsR7Rp78u7qrV0&fields=reviews&key=${apiKey}`
//       );

//       const reviewData = response.data.result.reviews;
//       console.log("objec111", response.data.result.reviews);
//       setReviews(reviewData);
//     } catch (error) {
//       console.error("Error fetching reviews:", error);
//     }
//   };
//   useEffect(() => {
//     fetchReviews();
//   }, []);

//   const lgBreakpoint = 992;
//   const mdBreakpoint = 768;

//   const windowWidth = window.innerWidth;

//   return (
//     <>
//       <section className="clients-main  purpleLIghtShade py-5">
//         <Container className=" responsiveMainForMobile">
//           <Row className="text-center">
//             <RubberBand duration={2000}>
//               <h2 className="responsiveFontLargeHeading abtBefore purpleText bolder pb-2">
//                 Clients words
//               </h2>
//               <h1 className="responsiveFontLargeMedium  text-center d-inline-block justify-content-center m-auto">
//                 What our clients say
//               </h1>

//             </RubberBand>
//           </Row>

//           <Slider className="owl-theme mt-4 pb-4 " {...options}>
//             {reviews.map((review, index) => (
//               <div key={index} className=" pb-4">
//                 <Col className="col-lg-10 cardTestinomial py-3 position-relative responsiveMainForMobile">
//                   <HeadShake duration={2000}>
//                     <Image src={quat} alt="dd" className="quatation" />
//                     <div className="innerAboutSecond mt-4">
//                       <div className="mainImage d-flex">
//                         <Image
//                           src={review?.profile_photo_url}
//                           alt="dghd"
//                           className="w-25 img-fluid usr"
//                         />
//                         <div className="d-flex flex-column justify-content-center ms-4">
//                           <span className="pb-2 purpleText medium">
//                             {review?.author_name}
//                           </span>
//                           <StarRatings
//                             rating={review?.rating}
//                             starRatedColor="#f39c12"
//                             numberOfStars={5}
//                             name="rating"
//                             starDimension="20px"
//                             starSpacing="2px"
//                           />
//                         </div>
//                       </div>
//                       <div className="text-justify ms-3 mt-3">
//                         <small className="responsiveFontLarge  blackShade medium pt-3 mt-3">
//                           {review?.text}
//                         </small>
//                       </div>
//                     </div>
//                   </HeadShake>
//                 </Col>
//               </div>
//             ))}
//           </Slider>
//         </Container>
//       </section>
//     </>
//   );
// }
