import React from 'react'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import Button from '@mui/material/Button'

const Unauthorised = () => {
  const history = useHistory()
  const goToTransaction = () => {
    history.push('/transactions')
  }
  return (
    <>
      <div className="row clearfix mt-5">
        <div className="container">
          <div className="wrapper ">
            <div className="content ">
              <div className="item border-0 d-flex justfy-content-center flex-column">
                <h1>3DS Verification Failed</h1>
                <p className="mt-3"> You didn't completed the extra verifications</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row d-flex justify-content-center ">
        <div className="col-lg-6">
          <Button
            className="m-0 mt-3 purpleBackground"
            variant="contained"
            onClick={goToTransaction}
          >
            Go To Transaction Page
          </Button>
        </div>
      </div>
    </>
  )
}

export default Unauthorised
