import {
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Tooltip,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { getIn } from "formik";
import React, { useEffect } from "react";

const RenderInput = ({
  inputField,
  formik,
  index: arrayIndex,
  isFieldArray,
  passwordProps,
}) => {
  const theme = useTheme();
  const isSm = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    if (formik.values.documentType && inputField.length) {
      const isSingleFile = inputField[0].singleFile;
      const onlyDocument = inputField[0].onlyDocument;
      const isCorporate = inputField[0].isCorporate;
      formik.setFieldValue("isSingleFile", isSingleFile);
      formik.setFieldValue("onlyDocument", onlyDocument);
      formik.setFieldValue("isCorporate", isCorporate);
    }
  }, [formik.values.documentType]);

  const getComponentToRender = (element, disableField, index) => {
    if (!element) return null;
    const formValues = isFieldArray
      ? getIn(formik.values, element.name)
      : formik.values[element.name];
    const formError = isFieldArray
      ? getIn(formik.errors, element.name)
      : formik.errors[element.name];
    const formTouched = isFieldArray
      ? getIn(formik.touched, element.name)
      : formik.touched[element.name];

    switch (element.type) {
      case "text":
        return (
          <TextField
            name={element?.name}
            label={element?.label}
            value={formValues}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            fullWidth
            required={element.required}
            variant="outlined"
            // className="input"
            disabled={element.isDisabled}
            error={formTouched && Boolean(formError)}
            onKeyPress={(ev) => {
              if (ev.key === "Enter") {
                formik.handleSubmit();
                ev.preventDefault();
              }
            }}
            inputProps={{
              minLength: element?.min,
              maxLength: element?.max,
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Tooltip
                  // title={`${showValues.showPassword ? "Hide" : "Show"
                  //   } Password`}
                  >
                    {/* <IconButton
                      aria-label="toggle password visibility"
                      // onClick={handleClickShowPassword}
                      // onMouseDown={handleMouseDownPassword}
                      edge="start"
                    > */}
                    {element?.iconStart}
                    {/* </IconButton> */}
                  </Tooltip>
                </InputAdornment>
              ),
            }}
            helperText={formTouched && formError}
            InputLabelProps={{ shrink: Boolean(formValues) }}
          />
        );
      case "password":
        const isConfirmPassword = element.name === "confirmPassword";
        return (
          <TextField
            name={element?.name}
            label={element?.label}
            value={formValues}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            fullWidth
            variant="outlined"
            disabled={element.isDisabled}
            error={formTouched && Boolean(formError)}
            helperText={formTouched && formError}
            InputLabelProps={{ shrink: Boolean(formValues) }}
            type={
              isConfirmPassword
                ? passwordProps?.showConfirmPassword
                  ? "text"
                  : "password"
                : passwordProps?.showPassword
                ? "text"
                : "password"
            }
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Tooltip
                    title={`${
                      isConfirmPassword
                        ? passwordProps?.showConfirmPassword
                          ? "Hide"
                          : "Show"
                        : passwordProps?.showPassword
                        ? "Hide"
                        : "Show"
                    } Password`}
                  >
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={
                        isConfirmPassword
                          ? passwordProps?.handleClickShowConfirmPassword
                          : passwordProps?.handleClickShowPassword
                      }
                      onMouseDown={passwordProps?.handleMouseDownPassword}
                      edge="end"
                    >
                      {isConfirmPassword
                        ? passwordProps?.showConfirmPassword
                          ? element?.iconEnd1
                          : element?.iconEnd2
                        : passwordProps?.showPassword
                        ? element?.iconEnd1
                        : element?.iconEnd2}
                    </IconButton>
                  </Tooltip>
                </InputAdornment>
              ),
              startAdornment: (
                <InputAdornment position="start">
                  <Tooltip>{element?.iconStart}</Tooltip>
                </InputAdornment>
              ),
            }}
          />
        );
      default:
        return <TextField name={element?.name} label={element?.label} />;
    }
  };

  return (
    <div>
      <Grid container spacing={2} alignItems="flex-end">
        {inputField?.map((element, index) => {
          const isDisabled = element?.disableOnChange?.name.some(
            (item, i) =>
              element.disableOnChange.value[i] === formik.values[item]
          );

          return (
            <>
              <Grid
                item
                sm={element?.sm}
                xs={element?.xs || element?.sm}
                md={element?.md}
                lg={element?.lg}
                mt={element?.mt}
                key={index}
              >
                {getComponentToRender(element, isDisabled, index)}
              </Grid>
              {element?.iconStart && (
                <style>{`
                .css-qfsifa-MuiFormLabel-root-MuiInputLabel-root {
                  padding-left: 22px;
                }
                .css-1kwbd39-MuiInputBase-root-MuiOutlinedInput-root {
                  padding-left: 6px;
                }
                .css-m984wp-MuiInputBase-root-MuiOutlinedInput-root {
                  padding-left: 6px;
                }
              `}</style>
              )}
            </>
          );
        })}
      </Grid>
    </div>
  );
};

export default RenderInput;
