import React, { useEffect, useState } from 'react';
import './Complaints.scss';
import PageHeader from '../../../../components/PageHeader';
import axios from 'axios';
import { CommonConstants } from '../../../../Constants/common.constants';
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import moment from 'moment';
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import CloseIcon from "@mui/icons-material/Close";
import Button from "@mui/material/Button";
import Form from 'react-bootstrap/Form';
import NoRecordWithAddBtn from '../../../../Helpers/NoRecord/NoRecordWithAddBtn';
import Pagination from '@mui/material/Pagination';
import Loader from '../../../Loader/Loader';
// import { saveAs } from 'file-saver';

export default function Complaints() {
  const [expanded, setExpanded] = useState(false);
  const handleExpand = () => {
    setExpanded(!expanded);
  };
  const [pageSize, setPageSize] = useState(1);
  const [fundData, setFundData] = useState([]);
  const [openNoteModal, setOpenNoteModal] = useState(false);
  const [Search, SetSearch] = React.useState('');
  const [RowsPerPage, SetRowsPerPage] = React.useState(CommonConstants.DefaultPageSize);
  const [Page, SetPage] = React.useState(1);
  const [loadervalue, setloadervalue] = useState(false);
  const [CountPage, SetCountPage] = React.useState(0);
  const [numItems, SetNumItems] = React.useState(0);
  const [showSearch, setShowSearch] = useState(false);

  const [showData, setShowData] = useState({
    "id": 0,
    "haveCustomerId": false,
    "customerId": "",
    "userId": 0,
    "fullName": "",
    "email": "",
    "phone": "",
    "title": "",
    "details": "",
    "createdAt": "",
    "updatedAt": "",
    "deleted": false
  });

  const handleNoteCloseModal = () => {
    setOpenNoteModal(false);
  };
  const handleOpenModel = async (id) => {
    await axios.post(CommonConstants.BASE_URL + "/getcomplaintbyid", { "id": id }).then(responce => {
      if (responce.data.status == true) {
        setOpenNoteModal(true);
        setShowData(responce.data.data);
      }
    }).catch(error => console.log(error))
  };
  const getAllReportFraud = async () => {
    setloadervalue(true);
    const payload = {
      "pageindex": Page,
      "pagesize": RowsPerPage,
      "searchdata": Search
    }
    await axios.post(CommonConstants.BASE_URL + '/getallcomplaints', payload).then((responce) => {
      setFundData(responce.data.data);
      SetCountPage(responce.data.totalPageCount);
      SetNumItems(responce.data.recordCount);
      setShowSearch(responce.data.recordCount == 0 ? (Search == "" ? false : true) : true);
    }).catch(error => {
      console.log(error);
    })
    setloadervalue(false);
  }

  const handleSearch = (e) => {
    const searchTerm = e.target.value;
    SetSearch(searchTerm);
    setShowSearch(fundData.length > 0 || searchTerm.trim() !== '');
  }


  const ChangeRowSelected = (Event) => {
    SetRowsPerPage(Number(Event.target.value));
    SetPage(1);
  };
  const HandleChangePage = (Event, NewPage) => {
    if (NewPage == Page) {
      SetPage(NewPage);
    } else {
      SetPage(NewPage);
    }
  };
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleExpanded = () => {
    setIsExpanded(!isExpanded);
  };
  useEffect(() => {
    getAllReportFraud();
  }, [Search, Page, RowsPerPage,]);
  return (
    <>
      {loadervalue == true ? <Loader /> : ""}
      <div className="container-fluid" onClick={() => {
        document.body.classList.remove("offcanvas-active");
      }}>
        <PageHeader HeaderText="Complaints" Breadcrumb={[{ name: "Dashboard", navigate: "" }, { name: "IoT Dashboard", navigate: "" }]} />
        <div className="row clearfix">
          <div className="col-lg-12 col-md-12">
            <div className="card">
              <div className="body project_report">
                <div className="table-responsive">
                  <div className="d-flex justify-content-between align-items-center respoChildFooter ">
                    {fundData && fundData.length > 0 && <div className="filter-row pb-2 ">
                      Show Entries &nbsp; &nbsp;
                      <div className="d-flex align-items-center pbSt">
                        <Form.Select
                          name="tbl_meeting_length"
                          onChange={ChangeRowSelected}
                          value={RowsPerPage}
                          aria-controls="tbl_meeting"
                          className="form-control-sm py-2 h-auto  ps-3 mt-1"
                        >
                          {CommonConstants.show_rows.map((value) => (
                            <option value={value}>{value}</option>
                          ))}
                        </Form.Select>
                      </div>
                    </div>}
                    {showSearch && <div className="d-flex align-items-center respoChildFooter">
                      <div className="form-group d-flex align-items-center mb-0">
                        <label className="font-weight-normal mb-0">Search: </label> &nbsp;&nbsp;
                        <input
                          type="search"
                          id="search"
                          className="form-control p-0 px-3 "
                          placeholder=""
                          value={Search}
                          onChange={handleSearch}
                        />
                      </div> &nbsp;
                    </div>}
                  </div>
                  <div className="row">
                  </div>
                  {
                    fundData && fundData.length > 0 ?
                      <div style={{ overflowX: "auto" }}>
                        <table className="table m-b-0 table-hover mt-3">
                          <thead className="thead-light">
                            <tr>
                              <th>Full Name</th>
                              <th>Email</th>
                              <th>Mobile</th>
                              <th>Customer Id</th>
                              <th>Date</th>
                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {
                              fundData && fundData.map((row) => {
                                return (
                                  <tr>
                                    <td>{row.fullName == null ? "-" : row.fullName}</td>
                                    <td>{row.email == "" ? "-" : row.email}</td>
                                    <td>{row.phone == "" ? "-" : row.phone}</td>
                                    <td>{row.customerId == "" ? '-' : row.customerId}</td>
                                    <td>{moment(row.createdAt).format('YYYY-MM-DD')}</td>
                                    <td className="project-actions">
                                      <a className="btn btn-outline-secondary mr-1" title='View' onClick={() => handleOpenModel(row.id)}>
                                        <RemoveRedEyeIcon />
                                      </a>{" "}
                                      &nbsp;
                                    </td>
                                  </tr>
                                )
                              })
                            }

                          </tbody>
                        </table>
                      </div>
                      : <NoRecordWithAddBtn />}
                </div>
                {fundData && fundData.length > 0 && <div className="d-flex justify-content-between pt-4 mr-4 align-items-center respoChildFooter">
                  <div className="filter-row pt-2">
                    {numItems > 0 ? `Showing ${(Page - 1) * RowsPerPage + 1} to ${(Page * RowsPerPage) > numItems ? numItems : (Page * RowsPerPage)} of ${numItems} entries` : "No records Available"}
                  </div>
                  <Pagination count={CountPage} className="pbDowSt pbSt" page={Page} onChange={HandleChangePage} color="secondary" shape="rounded" />
                </div>}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* view model  */}
      < Modal open={openNoteModal} onClose={handleNoteCloseModal} >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            backgroundColor: "white",
            padding: "30px",
            width: { xs: "90%", sm: "70%", md: "50%", lg: "40%" },
            paddingBottom: "20px",
          }}
        >
          <div className="border-bottom py-2 mb-4 d-flex justify-content-between">
            <h5>Details</h5>
            <div onClick={handleNoteCloseModal} className="pointer">
              <CloseIcon />
            </div>
          </div>
          <div className="">
            <div className="row">
              <div className="my-2">
                <ul className='list-unstyled'>
                  <li className="py-2 d-flex">
                    <div className="font-weight-normal col-lg-6"> Full Name : </div>
                    <div className="">{showData.fullName} </div>
                  </li>
                  <li className="py-2 d-flex">
                    <div className="font-weight-normal col-lg-6"> Email : </div>
                    <div className=" " style={{ overflowWrap: 'break-word' }}>{showData.email} </div>
                  </li>
                  <li className="py-2 d-flex">
                    <div className="font-weight-normal col-lg-6"> Mobile : </div>
                    <div className="">{showData.phone} </div>
                  </li>
                  <li className="py-2 d-flex">
                    <div className="font-weight-normal col-lg-6"> Customer Id : </div>
                    <div className="">{showData.customerId == "" ? "-" : showData.customerId} </div>
                  </li>
                  <li className="py-2 d-flex">
                    <div className="font-weight-normal col-lg-6"> Title : </div>
                    <div className="">{showData.title} </div>
                  </li>
                  <li className="py-2 d-flex">
                    <div className="font-weight-normal col-lg-6"> Details of matter: </div>
                    <div className='d-flex flex-column cursor-pointer'>
                      <div className="" style={{ overflowWrap: 'break-word' }}>
                        {isExpanded ? showData.details : `${showData.details.slice(0, 100)}...`}
                      </div>
                      <a className='pointer' onClick={toggleExpanded}>
                        {isExpanded ? 'Read Less' : 'Read More'}
                      </a>
                    </div>
                  </li>
                  <li className="py-2 d-flex">
                    <div className="font-weight-normal col-lg-6"> Date : </div>
                    <div className="">{moment(showData.createdAt).format('YYYY-MM-DD')} </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="row d-flex justify-content-end mt-3 border-top">
            <div className="col-lg-3">
              <Button
                className="m-0 mt-3"
                variant="outlined"
                onClick={handleNoteCloseModal}
              >
                Close
              </Button>
            </div>
          </div>
        </Box>
      </Modal >
    </>
  )
}