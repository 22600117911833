import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import "bootstrap/dist/css/bootstrap.min.css";

import React, { useEffect } from "react";
import { connect } from "react-redux";
import {
  Route,
  Redirect,
  Switch,
  withRouter,
  Link,
  useParams,
  useHistory,
} from "react-router-dom";
import Login from "./screens/Login";
import dashboard from "./screens/Dashbord/Dashbord";

import Users from "./screens/Dashbord/Users";
import NavbarMenu from "./components/NavbarMenu";
import appInbox from "./screens/App/Inbox";
import appChat from "./screens/App/Chat";
import appCalendar from "./screens/App/Calendar";
import appContact from "./screens/App/Contact";
import appTaskbar from "./screens/App/Taskbar";
import filemanagerdashboard from "./screens/FileManager/Dashboard";
import filedocuments from "./screens/FileManager/Documents";
import filemedia from "./screens/FileManager/Media";
import fileimages from "./screens/FileManager/Images";
import blognewPost from "./screens/Blog/NewPost";
import blogdetails from "./screens/Blog/BlogDetails";
import bloglist from "./screens/Blog/BlogList";
import uitypography from "./screens/UIElements/Typography";
import uitabs from "./screens/UIElements/Tabs";
import uibuttons from "./screens/UIElements/Button";
import bootstrapui from "./screens/UIElements/BootstrapUI";
import uiicons from "./screens/UIElements/Icons";
import uinotifications from "./screens/UIElements/Notifications";
import uicolors from "./screens/UIElements/Colors";
import uilistgroup from "./screens/UIElements/ListGroup";
import uimediaobject from "./screens/UIElements/MediaObject";
import uimodal from "./screens/UIElements/Modals";
import uiprogressbar from "./screens/UIElements/ProgressBar";
import widgetsdata from "./screens/Widgets/Data";
import widgetsweather from "./screens/Widgets/Weather";
import widgetsblog from "./screens/Widgets/Blog";
import widgetsecommers from "./screens/Widgets/ECommers";
import registration from "./screens/Auth/Registration";
import lockscreen from "./screens/Auth/Lockscreen";
import forgotpassword from "./screens/Auth/Login/ForgotPassword/ForgotPassword";
import Page404 from "./screens/Auth/Page404";
import page403 from "./screens/Auth/Page403";
import page500 from "./screens/Auth/Page500";
import page503 from "./screens/Auth/Page503";
import blankpage from "./screens/Pages/BlankPage";
import profilev1page from "./screens/Pages/ProfileV1";
import profilev2page from "./screens/Pages/ProfileV2";
import imagegalleryprofile from "./screens/Pages/ImageGallery";
import timeline from "./screens/Pages/TimeLine";
import pricing from "./screens/Pages/Pricing";
import invoices from "./screens/Pages/Invoices";
import invoicesv2 from "./screens/Pages/InvoicesV2";
import searchresult from "./screens/Pages/SearchResults";
import helperclass from "./screens/Pages/HelperClass";
import teamsboard from "./screens/Pages/TeamsBoard";
import projectslist from "./screens/Pages/ProjectsList";
import maintanance from "./screens/Pages/Maintanance";
import testimonials from "./screens/Pages/Testimonials";
import faqs from "./screens/Pages/Faqs";
import formvalidation from "./screens/Forms/FormValidation";
import basicelements from "./screens/Forms/BasicElements";
import tablenormal from "./screens/Tables/TableNormal";
import echart from "./screens/Charts/Echart";
import leafletmap from "./screens/Maps/GoogleMaps";
import Home from "../src/screens/Home/Home";
import DirectororSharholderUploadFile from "../src/screens/Helpers/ReVerifyIDDocument/DirectororSharholderUploadFile";
import LoginPage from "../src/screens/Login/Login";
import lrL_P from "../src/screens/Auth/Login/Login";
import Chat from "./screens/Dashbord/Chat";
import TransactionUtility from "./screens/Dashbord/TransactionUtility/TransactionUtility";
import FlightEnquiry from "./screens/Dashbord/FlightEnquiry";
import Promocode from "./screens/Dashbord/Promocode";
import ExchangeRate from "./screens/Dashbord/ExchangeRate";
import Signup from "./screens/Auth/Signup/Signup";
import SignupNew from "./screens/Auth/SignupNew/Signup";
import ResetPassword from "../src/screens/Auth/Login/ForgotPassword/ForgotPasswordPages/Resetpassword";
import SendMoney from "./screens/SendMoney/SendMoney";
import SendMoneyAbroad from "./screens/SendMoney/Moneygram/index";
import IndividualUser from "./screens/Dashbord/IndividualUser";
import Demo from "./screens/Dashbord/Demo/Demo";
import Info from "./screens/Dashbord/Info";
import ExchangeRateForm from "./screens/Dashbord/ExchangeRateForm";
import DeliveryMethod from "./screens/Dashbord/Settings/DeliveryMethods/DeliveryMethod";
import DeliveryMethodCreate from "./screens/Dashbord/Settings/DeliveryMethods/DeliveryMethodCreate";
import ServiceCharge from "./screens/Dashbord/ServiceCharge";
import CreateServiceCharge from "./screens/Dashbord/CreateServiceCharge";
import IdIssuingAuthority from "./screens/Dashbord/Settings/IdIssuingAuthority/IdIssuingAuthority";
import CreateIdIssuingAuthority from "./screens/Dashbord/Settings/CreateIdIssuingAuthority/CreateIdIssuingAuthority";
import PartnerBank from "./screens/Dashbord/PartnerBank/PartnerBank";
import CreatePartnerBank from "./screens/Dashbord/CreatePartnerBank/CreatePartnerBank";
import PaymentMethods from "./screens/Dashbord/Settings/PaymentMethods/PaymentMethods";
import CreatePaymentMethods from "./screens/Dashbord/Settings/CreatePaymentMethods/CreatePaymentMethods";
import UpdatePaymentMethods from "./screens/Dashbord/Settings/UpdatePaymentMethods/UpdatePaymentMethods";
import CountrySettings from "./screens/Dashbord/Settings/CountrySettings/CountrySettings";
import RiskManagement from "./screens/Dashbord/Compliance/RiskManagement/RiskManagement";
import CreateRiskManagement from "./screens/Dashbord/Compliance/RiskManagement/CreateRiskManagement";
import ProfilePage from "./screens/ProfilePage/ProfilePage";
import Settings from "./screens/Settings/Settings";
import ChatPage from "./screens/Chat/ChatPage";
import Statement from "./screens/Statement/Statement";
import TransactionPage from "./screens/TransactionPage/TransactionPage";
import CheckoutPaymentError from "./CheckoutPaymentError";
import Referal from "./screens/Referal/Referal";
import RecipientsPage from "./screens/RecipientsPage/RecipientsPage";
import NotificationPage from "./screens/NotificationPage/NotificationPage";
import Recievers from "./screens/Dashbord/Recievers/Recievers";
import NotificationTemplate from "./screens/Dashbord/NotificationTemplate/NotificationTemplate";
import NotificationTemplateCreate from "./screens/Dashbord/NotificationTemplate/NotificationTemplateCreate";
import Compliance from "./screens/Dashbord/Compliance/Compliance";
import Rewards from "./screens/Dashbord/Rewards/Rewards";
import Accounts from "./screens/Dashbord/Accounts/Accounts";
import Agents from "./screens/Dashbord/Agents/Agents";
import PayoutPartner from "./screens/Dashbord/Settings/PayoutPartner/PayoutPartner";
import PurposeOfTransfer from "./screens/Dashbord/Settings/PurposeOfTransfer/PurposeOfTransfer";
import Relation from "./screens/Dashbord/Settings/Relation/Relation";
import Occupation from "./screens/Dashbord/Settings/Occupation/Occupation";
import TypeOfID from "./screens/Dashbord/Settings/TypeOfID/TypeOfID";
import CreateTypeOfID from "./screens/Dashbord/Settings/TypeOfID/CreateTypeOfID";

import KYC from "./screens/Dashbord/Settings/KYC/KYC";
import SendMoneyForms from "./screens/Dashbord/Settings/SendMoneyForms/SendMoneyForms";
import UtilityServiceTransaction from "./screens/Dashbord/UtilityServiceTransaction/UtilityServiceTransaction";
import ReportedFund from "./screens/Dashbord/AdditionalSettings/ReportedFund/ReportedFund";
import Complaints from "./screens/Dashbord/AdditionalSettings/Complaints/Complaints";
import DeviceInfo from "./screens/Dashbord/AdditionalSettings/DeviceInfo/DeviceInfo";
import Reviews from "./screens/Dashbord/AdditionalSettings/Reviews/Reviews";
import ApiLogs from "./screens/Dashbord/AdditionalSettings/ApiLogs/ApiLogs";
import Refunds from "./screens/Dashbord/AdditionalSettings/Refunds/Refunds";
import AboutUsPage from "./screens/AboutUsPage/AboutUsPage";
import ContactUsPage from "./screens/ContactUsPage/ContactUsPage";
import BookFlight from "./screens/BookFlight/BookFlight";
import LumbiniGroup from "./screens/LumbiniGroup/LumbiniGroup";
import OurAchievement from "./screens/OurAchievement/OurAchievement";
import HelpResources from "./screens/HelpResources/HelpResources";
import RefundsPage from "./screens/RefundsPage/RefundsPage";
import ComplaintsPage from "./screens/ComplaintsPage/ComplaintsPage";
import ReportFraudPage from "./screens/ReportFraudPage/ReportFraudPage";
import NoticePage from "./screens/NoticePage/NoticePage";
import AgentsPage from "./screens/AgentsPage/AgentsPage";
import CountrySettingList from "./screens/Dashbord/Settings/CountrySettings/CountrySettingList";
import DeliveryMethodList from "./screens/Dashbord/Settings/DeliveryMethods/DeliveryMethodList";
import AddDeliveryType from "./screens/Dashbord/Settings/DeliveryMethods/AddDeliveryType";
import PaymentType from "./screens/Dashbord/Settings/PaymentMethods/PaymentType";
import AddPaymenttype from "./screens/Dashbord/Settings/PaymentMethods/AddPaymenttype";
import SendMoneyFormsCreate from "./screens/Dashbord/Settings/SendMoneyForms/SendMoneyFormsCreate";
import Legal from "./screens/Legal/Legal";
import TransactionViewPage from "./screens/Dashbord/TransactionUtility/TransactionViewPage/TransactionViewPage";
import ChangePassword from "./screens/Auth/Login/ForgotPassword/ChangePassword/ChangePassword";
import UserTransactionView from "./screens/Dashbord/UserTransactionView";
import Unauthorised from "./Unauthorised";
import TransactionInvoice from "./screens/Dashbord/TransactionUtility/TransactionInvoice/TransactionInvoice";
import AddAccounts from "./screens/Dashbord/Accounts/AddAccounts/AddAccounts";
import ChatScreen from "./screens/Dashbord/ChatScreen/ChatScreen";
import TableResponsive from "./screens/Dashbord/TransactionUtility/TableResponsive/TableResponsive";
import WhatWeDo from "./screens/WhatWeDo/WhatWeDo";
import ReferalRewards from "./screens/ReferalRewards/ReferalRewards";
import SecurityPage from "./screens/SecurityPage/SecurityPage";
import WhereIsMoney from "./screens/WhereIsMoney/WhereIsMoney";
import BecomeAnAgent from "./screens/BecomeAnAgent/BecomeAnAgent";
import UtilityBillPayment from "./screens/UtilityBillPayment/UtilityBillPayment";
import FraudAwareness from "./screens/FraudAwareness/FraudAwareness";
import PartnerAndAffiliate from "./screens/PartnerAndAffiliate/PartnerAndAffiliate";
import ComplianceSection from "./screens/ComplianceSection/ComplianceSection";
import ServiceRemittance from "./screens/ServiceRemittance/ServiceRemittance";
import YourAccount from "./screens/YourAccount/YourAccount";
import BankTransfer from "./screens/BankTransfer/BankTransfer";
import WalletTopup from "./screens/WalletTopup/WalletTopup";
import CashPickup from "./screens/CashPickup/CashPickup";
import TermsAndCondition from "./screens/TermsAndCondition/TermsAndCondition";
import Kycfrommangement from "./screens/Dashbord/Settings/KYC/KycFormManagement/Kycformmangement";
import VerifyEmailPage from "./screens/Auth/VerifyEmailPage";
import ContactUs from "./screens/Dashbord/AdditionalSettings/ConatctUs/ContactUs";
import AddPoints from "./screens/Dashbord/Rewards/RewardsForms/AddPoints";
import AgentForm from "./screens/BecomeAnAgent/AgentForm";
import CareersHiringPage from "./screens/CareersHiringPage/CareersHiringPage";
import CareersHiringForm from "./screens/CareersHiringPage/CareersHiringForm/CareersHiringForm";
import BlogsPage from "./screens/BlogsPage/BlogsPage";
import BlogsDetailsPage from "./screens/BlogsPage/BlogsDetailsPage/BlogsDetailsPage";
import FAQContent from "./screens/Dashbord/SiteContent/FAQContent/FAQContent";
import AddFAQContent from "./screens/Dashbord/SiteContent/FAQContent/AddFAQContent/AddFAQContent";
import PrivacyPolicy from "./screens/PrivacyPolicy/PrivacyPolicy";
import CookiesPolicy from "./screens/CookiesPolicy/CookiesPolicy";
import ExchangeRateAndServiceCharge from "./screens/ExchangeRateAndServiceCharge/ExchangeRateAndServiceCharge";
import ReviewListPage from "./screens/ReviewListPage/ReviewListPage";
import CMSManagement from "./screens/Dashbord/SiteContent/CMSManagement/CMSManagement";
import EditCMSPage from "./screens/Dashbord/SiteContent/CMSManagement/EditCMSPage/EditCMSPage";
import AboutUSContent from "./screens/Dashbord/SiteContent/AboutUSContent/AboutUSContent";
import EditAboutUSContentPage from "./screens/Dashbord/SiteContent/AboutUSContent/EditAboutUSContentPage/EditAboutUSContentPage";
import OurAgents from "./screens/Dashbord/AdditionalSettings/OurAgents/OurAgents";
import MobileRedirect from "./screens/Auth/Login/MobileRedirect";
import AgentsDetails from "./screens/Dashbord/Agents/AgentDetails/AgentDetails";
import BlogsManagement from "./screens/Dashbord/SiteContent/BlogsManagement/BlogsManagement";
import EditBlogsManagement from "./screens/Dashbord/SiteContent/BlogsManagement/EditBlogsManagement/EditBlogsManagement";
// import EditCareerManagement from "./screens/Dashbord/SiteContent/CareerManagement/EditCareerManagement/EditCareerManagement";
import CareerManagement from "./screens/Dashbord/SiteContent/CareerManagement/CareerManagement";
import ViewCareerManagement from "./screens/Dashbord/SiteContent/CareerManagement/ViewCareerManagement/ViewCareerManagement";
import EditReviews from "./screens/Dashbord/AdditionalSettings/Reviews/EditReviews/EditReviews";
import DynamicCMSPage from "./screens/DynamicCMSPage/DynamicCMSPage";
import FAQPage from "./screens/FAQPage/FAQPage";
import NewsPage from "./screens/Dashbord/SiteContent/NewsPage/NewsPage";
import AddCareerManagement from "./screens/Dashbord/SiteContent/CareerManagement/AddCareerManagement";
import SiteMapPage from "./screens/SiteMapPage/SiteMapPage";
import Conference from "./screens/Dashbord/Calls/Conference";
import Notification from "./screens/Dashbord/Notifications/Notification";
import Configuration from "./screens/Dashbord/SiteContent/Configuration/Configuration";
import AddUpdateConfig from "./screens/Dashbord/SiteContent/Configuration/AddUpdateConfig";
import CancelTransactions from "./screens/Dashbord/AdditionalSettings/CancelTransactions/CancelTransactions";
import UseAxiosInterceptors from './utils/useAxiosInterceptors'
import { getUser } from "./utils/useHelper";
import NewLoginPage from "./screens/Auth/Login/NewLoginPage/NewLoginPage";

window.__DEV__ = true;

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoad: true,
      userDetails: null
    };
  }

  render() {
    var res = window.location.pathname;
    var baseUrl = process.env.PUBLIC_URL;
    baseUrl = baseUrl.split("/");
    res = res.split("/");
    res = res.length > 0 ? res[baseUrl.length] : "/";
    res = res ? res : "/";
    const activeKey1 = res;
    let userDetails = getUser();
    let rollId = userDetails?.role;
    // let rollId = localStorage.getItem("rollID");
    // let rollId = 1;
    // let authenticateUser = localStorage.getItem("Id");
    // let authenticateUser = userDetails?.isSignupCompleted;
    let authenticateUser = true;
    
    let AdminResetPassword = localStorage.getItem("AdminResetPermission");

    return (
      <div id="wrapper">
        <UseAxiosInterceptors />

        {activeKey1 === "" ||
        activeKey1 === "/" ||
        activeKey1 === "registration" ||
        activeKey1 === "lockscreen" ||
        activeKey1 === "forgotpassword" ||
        activeKey1 === "verifyemail" ||
        activeKey1 === "page404" ||
        activeKey1 === "page403" ||
        activeKey1 === "page500" ||
        activeKey1 === "page503" ||
        activeKey1 === "maintanance" ? (
          <Switch>
            {/* {console.log("aaa", activeKey1)} */}
            {/* <Route exact path={`${process.env.PUBLIC_URL}`} component={Login} /> */}

            <Route exact path={`${process.env.PUBLIC_URL}/`} component={Home} />

            <Route
              exact
              path={`${process.env.PUBLIC_URL}/forgotpassword`}
              component={forgotpassword}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/page404`}
              component={Page404}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/verifyemail`}
              component={VerifyEmailPage}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/page403`}
              component={page403}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/page500`}
              component={page500}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/page503`}
              component={page503}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/registration`}
              component={registration}
            />
            <Route exact path={`registration`} component={registration} />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/lockscreen`}
              component={lockscreen}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/maintanance`}
              component={maintanance}
            />
          </Switch>
        ) : activeKey1 === "login" ||
          activeKey1 === "home" ||
          activeKey1 === "uploaddocument" ||
          activeKey1 === "Log_in" ||
          activeKey1 == "sendmoney" ||
          activeKey1 == "sendmoney1" ||
          activeKey1 == "review-page" ||
          activeKey1 == "careers" ||
          activeKey1 == "careers-form" ||
          activeKey1 == "blogspage" ||
          activeKey1 == "blogsdetailspage" ||
          activeKey1 == "remittanceservices" ||
          activeKey1 == "agentforms" ||
          activeKey1 == "legal" ||
          activeKey1 == "profile" ||
          activeKey1 == "help-resources" ||
          activeKey1 == "faq" ||
          activeKey1 == "refunds-page" ||
          activeKey1 == "contact-us" ||
          activeKey1 == "book-flight" ||
          activeKey1 == "referal" ||
          activeKey1 == "sitemap" ||
          activeKey1 == "complaints" ||
          activeKey1 == "report-fraud" ||
          activeKey1 == "agents-page" ||
          activeKey1 == "settings" ||
          activeKey1 == "chats" ||
          activeKey1 == "notification" ||
          activeKey1 == "statement" ||
          activeKey1 == "recipients" ||
          activeKey1 == "transactions" ||
          activeKey1 == "checkout-payment-error" ||
          activeKey1 === "resetpassword" ||
          activeKey1 === "changepassword" ||
          activeKey1 === "MobileRedirect" ||
          activeKey1 === "signup" ? (
          activeKey1 == "help-resources" ||
          activeKey1 == "uploaddocument" ||
          activeKey1 == "careers" ||
          activeKey1 == "careers-form" ||
          activeKey1 == "blogspage" ||
          activeKey1 == "blogsdetailspage" ||
          activeKey1 == "sitemap" ||
          activeKey1 == "remittanceservices" ||
          activeKey1 == "legal" ||
          activeKey1 == "agentforms" ? (
            <>
              <Route
                exact
                path={`${process.env.PUBLIC_URL}/help-resources`}
                component={HelpResources}
              />
              <Route
                exact
                path={`${process.env.PUBLIC_URL}/uploaddocument`}
                component={DirectororSharholderUploadFile}
              />
              <Route
                exact
                path={`${process.env.PUBLIC_URL}/careers`}
                component={CareersHiringPage}
              />
              <Route
                exact
                path={`${process.env.PUBLIC_URL}/careers-form`}
                component={CareersHiringForm}
              />
              <Route
                exact
                path={`${process.env.PUBLIC_URL}/blogspage`}
                component={BlogsPage}
              />
              <Route
                exact
                path={`${process.env.PUBLIC_URL}/blogsdetailspage`}
                component={BlogsDetailsPage}
              />
              <Route
                exact
                path={`${process.env.PUBLIC_URL}/agentforms`}
                component={AgentForm}
              />
              <Route
                exact
                path={`${process.env.PUBLIC_URL}/sitemap`}
                component={SiteMapPage}
              />
              <Route
                exact
                path={`${process.env.PUBLIC_URL}/remittanceservices`}
                component={ServiceRemittance}
              />
              <Route
                exact
                path={`${process.env.PUBLIC_URL}/legal`}
                component={Legal}
              />
            </>
          ) : (
            <>
              <Switch>
                {/*Old signup route*/}

                {/*<Route*/}
                {/*  exact*/}
                {/*  path={`${process.env.PUBLIC_URL}/signup`}*/}
                {/*  component={Signup}*/}
                {/*/>*/}

                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/signup`}
                  component={SignupNew}
                />
                {
                  authenticateUser && AdminResetPassword ? (
                    <>
                      <Route
                        exact
                        path={`${process.env.PUBLIC_URL}/changepassword`}
                        component={ChangePassword}
                      />
                      <Redirect to="/changepassword" />
                    </>
                  ) : (
                    authenticateUser ? (
                      <>
                        <Route
                          exact
                          path={`${process.env.PUBLIC_URL}/notification`}
                          component={NotificationPage}
                        />
                        <Route
                          exact
                          path={`${process.env.PUBLIC_URL}/sendmoney`}
                          component={SendMoney}
                        />
                        <Route
                          exact
                          path={`${process.env.PUBLIC_URL}/sendmoney1`}
                          component={SendMoneyAbroad}
                        />
                        <Route
                          exact
                          path={`${process.env.PUBLIC_URL}/profile`}
                          component={ProfilePage}
                        />
                        <Route
                          exact
                          path={`${process.env.PUBLIC_URL}/chats`}
                          component={ChatPage}
                        />
                        <Route
                          exact
                          path={`${process.env.PUBLIC_URL}/statement`}
                          component={Statement}
                        />
                        <Route
                          exact
                          path={`${process.env.PUBLIC_URL}/recipients`}
                          component={RecipientsPage}
                        />

                        <Route
                          exact
                          path={`${process.env.PUBLIC_URL}/changepassword`}
                          component={ChangePassword}
                        />
                        <Route
                          exact
                          path={`${process.env.PUBLIC_URL}/referal`}
                          component={Referal}
                        />
                        <Route
                          exact
                          path={`${process.env.PUBLIC_URL}/transactions`}
                          component={TransactionPage}
                        />
                        <Route
                          exact
                          path={`${process.env.PUBLIC_URL}/checkout-payment-error`}
                          component={CheckoutPaymentError}
                        />
                        <Route
                          exact
                          path={`${process.env.PUBLIC_URL}/settings`}
                          component={Settings}
                        />
                      </>
                    ) :
                      (
                        <>
                          <Redirect to="/login" />
                        </>
                      )
                  )
                }

                {/* {authenticateUser ? (
                <>
                  <Route
                    exact
                    path={`${process.env.PUBLIC_URL}/notification`}
                    component={NotificationPage}
                  />
                  <Route
                    exact
                    path={`${process.env.PUBLIC_URL}/sendmoney`}
                    component={SendMoney}
                  />
                  <Route
                    exact
                    path={`${process.env.PUBLIC_URL}/profile`}
                    component={ProfilePage}
                  />
                  <Route
                    exact
                    path={`${process.env.PUBLIC_URL}/chats`}
                    component={ChatPage}
                  />
                  <Route
                    exact
                    path={`${process.env.PUBLIC_URL}/statement`}
                    component={Statement}
                  />
                  <Route
                    exact
                    path={`${process.env.PUBLIC_URL}/recipients`}
                    component={RecipientsPage}
                  />

                  <Route
                    exact
                    path={`${process.env.PUBLIC_URL}/changepassword`}
                    component={ChangePassword}
                  />
                  <Route
                    exact
                    path={`${process.env.PUBLIC_URL}/referal`}
                    component={Referal}
                  />
                  <Route
                    exact
                    path={`${process.env.PUBLIC_URL}/transactions`}
                    component={TransactionPage}
                  />
                  <Route
                    exact
                    path={`${process.env.PUBLIC_URL}/settings`}
                    component={Settings}
                  />
                </>
              ) : (
                <>
                  <Redirect to="/login" />
                </>
              )} */}
              </Switch>

              <Route
                exact
                path={`${process.env.PUBLIC_URL}/login`}
                component={lrL_P}
                // component={NewLoginPage}
              />

              <Route
                exact
                path={`${process.env.PUBLIC_URL}/MobileRedirect`}
                component={MobileRedirect}
              />

              <Route
                exact
                path={`${process.env.PUBLIC_URL}/sitemap`}
                component={SiteMapPage}
              />

              {/* <Route
              exact
              path={`${process.env.PUBLIC_URL}/exchangerate-servicecharge`}
              component={ExchangeRateAndServiceCharge}
            /> */}

              <Route
                exact
                path={`${process.env.PUBLIC_URL}/resetpassword`}
                component={ResetPassword}
              />

              {/* <Route
              exact
              path={`${process.env.PUBLIC_URL}/about-us`}
              component={AboutUsPage}
            /> */}

              <Route
                exact
                path={`${process.env.PUBLIC_URL}/review-page`}
                component={ReviewListPage}
              />

              {/* <Route
              exact
              path={`${process.env.PUBLIC_URL}/security`}
              component={SecurityPage}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/termsandconditions`}
              component={TermsAndCondition}
            /> */}
              {/* <Route
              exact
              path={`${process.env.PUBLIC_URL}/privacy-policy`}
              component={PrivacyPolicy}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/cookies-policy`}
              component={CookiesPolicy}
            /> */}
              {/* <Route
              exact
              path={`${process.env.PUBLIC_URL}/youraccount`}
              component={YourAccount}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/banktransfer`}
              component={BankTransfer}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/wallettopup`}
              component={WalletTopup}
            /> */}
              {/* <Route
              exact
              path={`${process.env.PUBLIC_URL}/cashpickup`}
              component={CashPickup}
            /> */}
              {/* <Route
              exact
              path={`${process.env.PUBLIC_URL}/utilitybillpayment`}
              component={UtilityBillPayment}
            /> */}
              {/* <Route
              exact
              path={`${process.env.PUBLIC_URL}/fraudawareness`}
              component={FraudAwareness}
            /> */}

              {/* <Route
              exact
              path={`${process.env.PUBLIC_URL}/compliancesection`}
              component={ComplianceSection}
            /> */}

              {/* <Route
              exact
              path={`${process.env.PUBLIC_URL}/partnerandaffiliate`}
              component={PartnerAndAffiliate}
            /> */}
              {/* <Route
              exact
              path={`${process.env.PUBLIC_URL}/remittanceservices`}
              component={ServiceRemittance}
            /> */}
              {/* <Route
              exact
              path={`${process.env.PUBLIC_URL}/becomeagent`}
              component={BecomeAnAgent}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/whereismoney`}
              component={WhereIsMoney}
            /> */}
              {/* <Route
              exact
              path={`${process.env.PUBLIC_URL}/what-we-do`}
              component={WhatWeDo}
            /> */}
              {/* <Route
              exact
              path={`${process.env.PUBLIC_URL}/referal-rewards`}
              component={ReferalRewards}
            /> */}
              {/* <Route
              exact
              path={`${process.env.PUBLIC_URL}/lumbini-group`}
              component={LumbiniGroup}
            /> */}
              <Route
                exact
                path={`${process.env.PUBLIC_URL}/book-flight`}
                component={BookFlight}
              />

              <Route
                exact
                path={`${process.env.PUBLIC_URL}/contact-us`}
                component={ContactUsPage}
              />
              <Route
                exact
                path={`${process.env.PUBLIC_URL}/loginpage`}
                component={LoginPage}
              />

              {/* <Route
              exact
              path={`${process.env.PUBLIC_URL}/achievement`}
              component={OurAchievement}
            /> */}

              <Route
                exact
                path={`${process.env.PUBLIC_URL}/faq`}
                component={FAQPage}
              />

              <Route
                exact
                path={`${process.env.PUBLIC_URL}/refunds-page`}
                component={RefundsPage}
              />

              <Route
                exact
                path={`${process.env.PUBLIC_URL}/complaints`}
                component={ComplaintsPage}
              />
              <Route
                exact
                path={`${process.env.PUBLIC_URL}/report-fraud`}
                component={ReportFraudPage}
              />

              <Route
                exact
                path={`${process.env.PUBLIC_URL}/agents-page`}
                component={AgentsPage}
              />

              {/* <Route
              exact
              path={`${process.env.PUBLIC_URL}/notice`}
              component={NoticePage}
            /> */}

              <Route
                exact
                path={`${process.env.PUBLIC_URL}/legal`}
                component={Legal}
              />
            </>
          )
        ) : activeKey1 == "about-us" ||
          activeKey1 == "lumbini-group" ||
          activeKey1 == "achievement" ||
          activeKey1 == "what-we-do" ||
          activeKey1 == "referal-rewards" ||
          activeKey1 === "notice" ||
          activeKey1 == "becomeagent" ||
          activeKey1 == "security" ||
          activeKey1 == "whereismoney" ||
          activeKey1 == "youraccount" ||
          activeKey1 == "banktransfer" ||
          activeKey1 == "wallettopup" ||
          activeKey1 == "cashpickup" ||
          activeKey1 == "utilitybillpayment" ||
          activeKey1 == "partnerandaffiliate" ||
          activeKey1 == "exchangerate-servicecharge" ||
          activeKey1 == "fraudawareness" ||
          activeKey1 == "termsandconditions" ||
          activeKey1 == "privacy-policy" ||
          activeKey1 == "cookies-policy" ||
          activeKey1 == "compliancesection" ? (
          <Route path="/:id" component={DynamicCMSPage} />
        ) : (
          <>
            <NavbarMenu history={this.props.history} activeKey={activeKey1} />
            <div id="main-content">
              <Switch>
                {rollId == "ADMIN" ? (
                  <>
                    {/* <Route
          path={`${process.env.PUBLIC_URL}/:id`}
          component={DynamicCMSPage}
          /> */}
                    <Route
                      exact
                      path={`${process.env.PUBLIC_URL}/dashboard`}
                      component={dashboard}
                    />
                    <Route
                      exact
                      path={`${process.env.PUBLIC_URL}/Kycfrommangement`}
                      component={Kycfrommangement}
                    />
                    <Route
                      exact
                      path={`${process.env.PUBLIC_URL}/users`}
                      component={Users}
                    />
                    <Route
                      exact
                      path={`${process.env.PUBLIC_URL}/deliveryMethod`}
                      component={DeliveryMethod}
                    />
                    <Route
                      exact
                      path={`${process.env.PUBLIC_URL}/deliveryMethodCreate`}
                      component={DeliveryMethodCreate}
                    />

                    <Route
                      exact
                      path={`${process.env.PUBLIC_URL}/individualuser`}
                      component={IndividualUser}
                    />

                    <Route
                      exact
                      path={`${process.env.PUBLIC_URL}/info`}
                      component={Info}
                    />

                    <Route
                      exact
                      path={`${process.env.PUBLIC_URL}/demo`}
                      component={Demo}
                    />

                    <Route
                      exact
                      path={`${process.env.PUBLIC_URL}/reciever`}
                      component={Recievers}
                    />
                    <Route
                      exact
                      path={`${process.env.PUBLIC_URL}/chat`}
                      component={Chat}
                    />
                    <Route
                      exact
                      path={`${process.env.PUBLIC_URL}/notifications`}
                      component={Notification}
                    />
                    <Route
                      exact
                      path={`${process.env.PUBLIC_URL}/transaction-utility`}
                      component={TableResponsive}
                    />
                    <Route
                      exact
                      path={`${process.env.PUBLIC_URL}/transaction-view`}
                      component={TransactionViewPage}
                    />
                    <Route
                      exact
                      path={`${process.env.PUBLIC_URL}/user-transaction-view`}
                      component={UserTransactionView}
                    />

                    <Route
                      exact
                      path={`${process.env.PUBLIC_URL}/compliance`}
                      component={Compliance}
                    />
                    <Route
                      exact
                      path={`${process.env.PUBLIC_URL}/transaction-invoice`}
                      component={TransactionInvoice}
                    />
                    <Route
                      exact
                      path={`${process.env.PUBLIC_URL}/rewards`}
                      component={Rewards}
                    />
                    <Route
                      exact
                      path={`${process.env.PUBLIC_URL}/agents`}
                      component={Agents}
                    />

                    <Route
                      exact
                      path={`${process.env.PUBLIC_URL}/accounts`}
                      component={Accounts}
                    />
                    <Route
                      exact
                      path={`${process.env.PUBLIC_URL}/add-accounts`}
                      component={AddAccounts}
                    />

                    <Route
                      exact
                      path={`${process.env.PUBLIC_URL}/flight-enquiry`}
                      component={FlightEnquiry}
                    />
                    <Route
                      exact
                      path={`${process.env.PUBLIC_URL}/exchange-rate`}
                      component={ExchangeRate}
                    />
                    <Route
                      exact
                      path={`${process.env.PUBLIC_URL}/id-issuing-authority`}
                      component={IdIssuingAuthority}
                    />
                    <Route
                      exact
                      path={`${process.env.PUBLIC_URL}/create-id-issuing-authority`}
                      component={CreateIdIssuingAuthority}
                    />
                    <Route
                      exact
                      path={`${process.env.PUBLIC_URL}/service-charge`}
                      component={ServiceCharge}
                    />
                    <Route
                      exact
                      path={`${process.env.PUBLIC_URL}/create-service-charge`}
                      component={CreateServiceCharge}
                    />
                    <Route
                      exact
                      path={`${process.env.PUBLIC_URL}/partner-bank`}
                      component={PartnerBank}
                    />

                    <Route
                      exact
                      path={`${process.env.PUBLIC_URL}/create-partner-bank`}
                      component={CreatePartnerBank}
                    />

                    <Route
                      exact
                      path={`${process.env.PUBLIC_URL}/payment-methods`}
                      component={PaymentMethods}
                    />

                    <Route
                      exact
                      path={`${process.env.PUBLIC_URL}/create-payment-methods`}
                      component={CreatePaymentMethods}
                    />

                    <Route
                      exact
                      path={`${process.env.PUBLIC_URL}/Update-payment-methods`}
                      component={UpdatePaymentMethods}
                    />

                    <Route
                      exact
                      path={`${process.env.PUBLIC_URL}/payout-partners`}
                      component={PayoutPartner}
                    />

                    <Route
                      exact
                      path={`${process.env.PUBLIC_URL}/purpose-of-transfer`}
                      component={PurposeOfTransfer}
                    />

                    <Route
                      exact
                      path={`${process.env.PUBLIC_URL}/relation`}
                      component={Relation}
                    />

                    <Route
                      exact
                      path={`${process.env.PUBLIC_URL}/occupation`}
                      component={Occupation}
                    />

                    <Route
                      exact
                      path={`${process.env.PUBLIC_URL}/typeofid`}
                      component={TypeOfID}
                    />
                    <Route
                      exact
                      path={`${process.env.PUBLIC_URL}/create-type-of-id`}
                      component={CreateTypeOfID}
                    />

                    <Route
                      exact
                      path={`${process.env.PUBLIC_URL}/country-settings-list`}
                      component={CountrySettingList}
                    />
                    <Route
                      exact
                      path={`${process.env.PUBLIC_URL}/country-settings`}
                      component={CountrySettings}
                    />

                    <Route
                      exact
                      path={`${process.env.PUBLIC_URL}/send-money-forms`}
                      component={SendMoneyForms}
                    />
                    <Route
                      exact
                      path={`${process.env.PUBLIC_URL}/send-money-forms-create`}
                      component={SendMoneyFormsCreate}
                    />
                    <Route
                      exact
                      path={`${process.env.PUBLIC_URL}/reported-fraud`}
                      component={ReportedFund}
                    />
                    <Route
                      exact
                      path={`${process.env.PUBLIC_URL}/faq-content`}
                      component={FAQContent}
                    />
                    <Route
                      exact
                      path={`${process.env.PUBLIC_URL}/news`}
                      component={NewsPage}
                    />
                    <Route
                      exact
                      path={`${process.env.PUBLIC_URL}/add-faq-content`}
                      component={AddFAQContent}
                    />

                    <Route
                      exact
                      path={`${process.env.PUBLIC_URL}/CMS`}
                      component={CMSManagement}
                    />

                    <Route
                      exact
                      path={`${process.env.PUBLIC_URL}/edit-CMS`}
                      component={EditCMSPage}
                    />

                    <Route
                      exact
                      path={`${process.env.PUBLIC_URL}/blogsmangement`}
                      component={BlogsManagement}
                    />

                    <Route
                      exact
                      path={`${process.env.PUBLIC_URL}/edit-blogs`}
                      component={EditBlogsManagement}
                    />

                    <Route
                      exact
                      path={`${process.env.PUBLIC_URL}/edit-reviews`}
                      component={EditReviews}
                    />

                    <Route
                      exact
                      path={`${process.env.PUBLIC_URL}/aboutus-CMS`}
                      component={AboutUSContent}
                    />
                    <Route
                      exact
                      path={`${process.env.PUBLIC_URL}/siteconfig`}
                      component={Configuration}
                    />
                    <Route
                      exact
                      path={`${process.env.PUBLIC_URL}/addupdateconfig`}
                      component={AddUpdateConfig}
                    />
                    <Route
                      exact
                      path={`${process.env.PUBLIC_URL}/editaboutus-CMS`}
                      component={EditAboutUSContentPage}
                    />

                    <Route
                      exact
                      path={`${process.env.PUBLIC_URL}/careermanagement`}
                      component={CareerManagement}
                    />

                    <Route
                      exact
                      path={`${process.env.PUBLIC_URL}/view-careermanagement`}
                      component={ViewCareerManagement}
                    />

                    <Route
                      exact
                      path={`${process.env.PUBLIC_URL}/add-careermanagement`}
                      component={AddCareerManagement}
                    />

                    <Route
                      exact
                      path={`${process.env.PUBLIC_URL}/refund`}
                      component={Refunds}
                    />
                    <Route
                      exact
                      path={`${process.env.PUBLIC_URL}/canceltransactions`}
                      component={CancelTransactions}
                    />

                    <Route
                      exact
                      path={`${process.env.PUBLIC_URL}/complaints-admin`}
                      component={Complaints}
                    />

                    <Route
                      exact
                      path={`${process.env.PUBLIC_URL}/refunds`}
                      component={Refunds}
                    />
                    <Route
                      exact
                      path={`${process.env.PUBLIC_URL}/contactUs`}
                      component={ContactUs}
                    />
                    <Route
                      exact
                      path={`${process.env.PUBLIC_URL}/ouragents`}
                      component={OurAgents}
                    />
                    <Route
                      exact
                      path={`${process.env.PUBLIC_URL}/agent-details`}
                      component={AgentsDetails}
                    />
                    <Route
                      exact
                      path={`${process.env.PUBLIC_URL}/deviceinfo`}
                      component={DeviceInfo}
                    />

                    <Route
                      exact
                      path={`${process.env.PUBLIC_URL}/api-logs`}
                      component={ApiLogs}
                    />

                    <Route
                      exact
                      path={`${process.env.PUBLIC_URL}/reviews`}
                      component={Reviews}
                    />

                    <Route
                      exact
                      path={`${process.env.PUBLIC_URL}/utility-service-transaction`}
                      component={UtilityServiceTransaction}
                    />

                    <Route
                      exact
                      path={`${process.env.PUBLIC_URL}/exchange-rate-form`}
                      component={ExchangeRateForm}
                    />

                    <Route
                      exact
                      path={`${process.env.PUBLIC_URL}/risk-management`}
                      component={RiskManagement}
                    />

                    <Route
                      exact
                      path={`${process.env.PUBLIC_URL}/create-risk-management`}
                      component={CreateRiskManagement}
                    />
                    <Route
                      exact
                      path={`${process.env.PUBLIC_URL}/notification-template`}
                      component={NotificationTemplate}
                    />
                    <Route
                      exact
                      path={`${process.env.PUBLIC_URL}/notification-template-create`}
                      component={NotificationTemplateCreate}
                    />
                    <Route
                      exact
                      path={`${process.env.PUBLIC_URL}/promocode`}
                      component={Promocode}
                    />
                    <Route
                      exact
                      path={`${process.env.PUBLIC_URL}/appinbox`}
                      component={appInbox}
                    />
                    <Route
                      exact
                      path={`${process.env.PUBLIC_URL}/appchat`}
                      component={appChat}
                    />
                    <Route
                      exact
                      path={`${process.env.PUBLIC_URL}/appcalendar`}
                      component={appCalendar}
                    />
                    <Route
                      exact
                      path={`${process.env.PUBLIC_URL}/appcontact`}
                      component={appContact}
                    />
                    <Route
                      exact
                      path={`${process.env.PUBLIC_URL}/apptaskbar`}
                      component={appTaskbar}
                    />
                    <Route
                      exact
                      path={`${process.env.PUBLIC_URL}/filemanagerdashboard`}
                      component={filemanagerdashboard}
                    />
                    <Route
                      exact
                      path={`${process.env.PUBLIC_URL}/filedocuments`}
                      component={filedocuments}
                    />
                    <Route
                      exact
                      path={`${process.env.PUBLIC_URL}/filemedia`}
                      component={filemedia}
                    />
                    <Route
                      exact
                      path={`${process.env.PUBLIC_URL}/fileimages`}
                      component={fileimages}
                    />
                    <Route
                      exact
                      path={`${process.env.PUBLIC_URL}/blognewpost`}
                      component={blognewPost}
                    />
                    <Route
                      exact
                      path={`${process.env.PUBLIC_URL}/blogdetails`}
                      component={blogdetails}
                    />
                    <Route
                      exact
                      path={`${process.env.PUBLIC_URL}/bloglist`}
                      component={bloglist}
                    />
                    <Route
                      exact
                      path={`${process.env.PUBLIC_URL}/uitypography`}
                      component={uitypography}
                    />
                    <Route
                      exact
                      path={`${process.env.PUBLIC_URL}/uitabs`}
                      component={uitabs}
                    />
                    <Route
                      exact
                      path={`${process.env.PUBLIC_URL}/bootstrapui`}
                      component={bootstrapui}
                    />
                    <Route
                      exact
                      path={`${process.env.PUBLIC_URL}/uiicons`}
                      component={uiicons}
                    />
                    <Route
                      exact
                      path={`${process.env.PUBLIC_URL}/uinotifications`}
                      component={uinotifications}
                    />
                    <Route
                      exact
                      path={`${process.env.PUBLIC_URL}/uicolors`}
                      component={uicolors}
                    />
                    <Route
                      exact
                      path={`${process.env.PUBLIC_URL}/uilistgroup`}
                      component={uilistgroup}
                    />
                    <Route
                      exact
                      path={`${process.env.PUBLIC_URL}/uimediaobject`}
                      component={uimediaobject}
                    />
                    <Route
                      exact
                      path={`${process.env.PUBLIC_URL}/uimodal`}
                      component={uimodal}
                    />
                    <Route
                      exact
                      path={`${process.env.PUBLIC_URL}/uibuttons`}
                      component={uibuttons}
                    />
                    <Route
                      exact
                      path={`${process.env.PUBLIC_URL}/uiprogressbar`}
                      component={uiprogressbar}
                    />
                    <Route
                      exact
                      path={`${process.env.PUBLIC_URL}/widgetsdata`}
                      component={widgetsdata}
                    />
                    <Route
                      exact
                      path={`${process.env.PUBLIC_URL}/widgetsweather`}
                      component={widgetsweather}
                    />
                    <Route
                      exact
                      path={`${process.env.PUBLIC_URL}/widgetsblog`}
                      component={widgetsblog}
                    />
                    <Route
                      exact
                      path={`${process.env.PUBLIC_URL}/widgetsecommers`}
                      component={widgetsecommers}
                    />
                    <Route
                      exact
                      path={`${process.env.PUBLIC_URL}/blankpage`}
                      component={blankpage}
                    />
                    <Route
                      exact
                      path={`${process.env.PUBLIC_URL}/profilev1page`}
                      component={profilev1page}
                    />
                    <Route
                      exact
                      path={`${process.env.PUBLIC_URL}/profilev2page`}
                      component={profilev2page}
                    />
                    <Route
                      exact
                      path={`${process.env.PUBLIC_URL}/imagegalleryprofile`}
                      component={imagegalleryprofile}
                    />
                    <Route
                      exact
                      path={`${process.env.PUBLIC_URL}/timeline`}
                      component={timeline}
                    />
                    <Route
                      exact
                      path={`${process.env.PUBLIC_URL}/pricing`}
                      component={pricing}
                    />
                    <Route
                      exact
                      path={`${process.env.PUBLIC_URL}/chatscreen`}
                      component={ChatScreen}
                    />
                    <Route
                      exact
                      path={`${process.env.PUBLIC_URL}/invoices`}
                      component={invoices}
                    />
                    <Route
                      exact
                      path={`${process.env.PUBLIC_URL}/invoicesv2`}
                      component={invoicesv2}
                    />
                    <Route
                      exact
                      path={`${process.env.PUBLIC_URL}/searchresult`}
                      component={searchresult}
                    />
                    <Route
                      exact
                      path={`${process.env.PUBLIC_URL}/helperclass`}
                      component={helperclass}
                    />
                    <Route
                      exact
                      path={`${process.env.PUBLIC_URL}/teamsboard`}
                      component={teamsboard}
                    />
                    <Route
                      exact
                      path={`${process.env.PUBLIC_URL}/projectslist`}
                      component={projectslist}
                    />
                    <Route
                      exact
                      path={`${process.env.PUBLIC_URL}/testimonials`}
                      component={testimonials}
                    />
                    <Route
                      exact
                      path={`${process.env.PUBLIC_URL}/faqs`}
                      component={faqs}
                    />
                    <Route
                      exact
                      path={`${process.env.PUBLIC_URL}/formvalidation`}
                      component={formvalidation}
                    />
                    <Route
                      exact
                      path={`${process.env.PUBLIC_URL}/basicelements`}
                      component={basicelements}
                    />
                    <Route
                      exact
                      path={`${process.env.PUBLIC_URL}/tablenormal`}
                      component={tablenormal}
                    />
                    <Route
                      exact
                      path={`${process.env.PUBLIC_URL}/echart`}
                      component={echart}
                    />
                    <Route
                      exact
                      path={`${process.env.PUBLIC_URL}/leafletmap`}
                      component={leafletmap}
                    />
                    <Route
                      exact
                      path={`${process.env.PUBLIC_URL}/deliverymethodlist`}
                      component={DeliveryMethodList}
                    />
                    <Route
                      exact
                      path={`${process.env.PUBLIC_URL}/adddeliverytype`}
                      component={AddDeliveryType}
                    />
                    <Route
                      exact
                      path={`${process.env.PUBLIC_URL}/addpoints`}
                      component={AddPoints}
                    />
                    <Route
                      exact
                      path={`${process.env.PUBLIC_URL}/paymenttype`}
                      component={PaymentType}
                    />
                    <Route
                      exact
                      path={`${process.env.PUBLIC_URL}/addpaymenttype`}
                      component={AddPaymenttype}
                    />
                    <Route
                      exact
                      path={`${process.env.PUBLIC_URL}/call`}
                      component={Conference}
                    />
                  </>
                ) : (
                  <>
                    <Page404 />
                  </>
                )}
              </Switch>
            </div>
          </>
        )}
      </div>
    );
  }
}

const mapStateToProps = ({ loginReducer }) => ({
  isLoggedin: loginReducer.isLoggedin,
});

export default withRouter(connect(mapStateToProps, {})(App));
