import { jwtDecode } from "jwt-decode";

export const getUser = () => {
  const auth = localStorage?.getItem("token");
  if (auth) {
    // const { authToken } = JSON.parse(auth);
    const decodedInfo = jwtDecode(auth);
    return decodedInfo;
  } else {
    return {};
  }
};

export const setUser = (token) => {
  if (token) {
    localStorage.setItem("token", token);
  }
};

export const setRefreshToken = (refreshToken) => {
  if (refreshToken) {
    localStorage.setItem("refreshToken", refreshToken);
  }
};

export const getUserToken = () => {
  return localStorage.getItem("token");
};
