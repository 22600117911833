import React from 'react';
import './Loader.scss'

const Loader = () => {
  return (
    <>
    <div class="loader-wrapper">
    <div class="loader"></div>
  </div>
    </>
  );
};

export default Loader;
