import one1 from './Group.svg';
import one2 from './Group-1.svg';
import one3 from './Group-2.svg';
import one4 from './Group-3.svg';
import one5 from './Group-4.svg';
import one9 from './Group-8.svg';
import one10 from './Group-9.svg';
import one11 from './Group-10.svg';
import one12 from './Group-11.svg';



export default [
  one1,one2,one3,one4,one5,one9,one10,one11,one12
  ];
  