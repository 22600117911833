import React, { useState } from "react";
import "../../../assets/assets/scss/pages/HowItWorks.scss";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";
import Row from "react-bootstrap/Row";
import Card from "react-bootstrap/Card";
import one from "../../../assets/images/oneLeave.png";
import two from "../../../assets/images/twoLeave.png";
import three from "../../../assets/images/threeLeave.png";
import four from "../../../assets/images/fourLeave.png";
import five from "../../../assets/images/fiveLeave.png";
import six from "../../../assets/images/sixLeave.png";
import seven from "../../../assets/images/howitvector.svg";
import LightSpeed from "react-reveal/LightSpeed";
import Roll from "react-reveal/Roll";
import Swing from "react-reveal/Swing";
import Rotate from "react-reveal/Rotate";

export default function HowItWorks({ children }) {
  return (
    <>
      <section className="bg-white how pt-1 mt-1">
        <Container>
        <Row className="text-center my-4">
           
           
              <h2 className="responsiveFontLargeHeading abtBefore purpleText bolder pb-2">
              How it work
              </h2>
              <h1 className="responsiveFontLargeMedium  text-center d-inline-block justify-content-center m-auto">
              How to send money with LegalRemit
              </h1>
              {/* <span className="responsiveFontLarge blackShade mt-4 text-center d-flex justify-content-center m-auto">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                Vestibulum quis ipsum ac dui sagittis{" "}
              </span> */}

 
          </Row>
          <div className="leavesMain my-5 d-flex">
            <div className="left-side">
              <div className="my-3 leavesChild1">
                <div className="media mright">
                  <div className="media-body">
                    <h4 className="media-heading text-black lh-base">
                    Sign Up for your account / Create a FREE account
                    </h4>
                    <div className="my-3">
                    You should create your profile using your name, email, and mobile number. Email and mobile numbers must be verified to continue using the verification link and OTP sent to your mobile number. The name must be matched with the name on your ID. 
                    </div>
                  </div>
                  <div className="media-right">
                    {" "}
                    <a>
                      {" "}
                      <img alt="" className="media-object moneySendRespo" src={one} />{" "}
                    </a>{" "}
                  </div>
                </div>
              </div>

              <div className="my-3 leavesChild1">
                <div className="media mright">
                  <div className="media-body">
                    <h4 className="media-heading text-black">Enter your KYC details:</h4>
                    <div className="my-3">
                    Once you verify your mobile with OTP, we ask you to fill in your KYC details. We need basic information about your current residential address (Including house number and postcode), your nationality, and occupation. Please click on save and next to move to the next step.
                    </div>
                  </div>
                  <div className="media-right">
                    {" "}
                    <a>
                      {" "}
                      <img alt="" className="media-object moneySendRespo" src={two} />{" "}
                    </a>{" "}
                  </div>
                </div>
              </div>

              <div className="my-3 leavesChild1">
                <div className="media mright">
                  <div className="media-body">
                    <h4 className="media-heading text-black">Choose ID Upload Method:</h4>
                    <div className="my-3">
                    We require you to provide your ID details and ID copy in this step. We only accept two types of IDs at the moment. Passport (any country), and Australian Driver's license. You should choose the type of ID from the given list, and provide an ID number, Its expiry date, and issuing authority. Once you complete this step, review all the details and submit your KYC. 
                    </div>
                  </div>
                  <div className="media-right">
                    {" "}
                    <a>
                      {" "}
                      <img alt="" className="media-object moneySendRespo" src={three} />{" "}
                    </a>{" "}
                  </div>
                </div>
              </div>
            </div>
            <div className="my-3 leavesChild1 d-flex">
              <img src={seven} className="d-flex" />
            </div>
            <div className="right-side">
              <div className="my-3 leavesChild1">
                <div className="media mleft">
                  <div className="media-left">
                    {" "}
                    <a>
                      {" "}
                      <img alt="" className="media-object moneySendRespo" src={four} />{" "}
                    </a>{" "}
                  </div>
                  <div className="media-body">
                    <h4 className="media-heading text-black">
                    Send Money:
                    </h4>
                    <div className="my-3">
                    After completion of the registration, you are ready to send money now, Choose country you wnat to send money, enter amount details and along with the recipient details and delivery method.
                    </div>
                  </div>
                </div>
              </div>
              <br/>
              <div className="my-3 leavesChild1">
                <div className="media mleft">
                  <div className="media-left">
                    {" "}
                    <a>
                      {" "}
                      <img alt="" className="media-object moneySendRespo" src={five} />{" "}
                    </a>{" "}
                  </div>
                  <div className="media-body">
                    <h4 className="media-heading text-black">Pay to Legal Remit:</h4>
                    <div className="my-3">
                    After completing other send noney steps, choose your preferred payment method to make payments to Legal Remit. You can choose from the available lists. Please note that, POLI is no longer supported. 
                    </div>
                  </div>
                </div>
              </div>
              <br/>
              <div className="my-3 leavesChild1">
                <div className="media mleft">
                  <div className="media-left">
                    {" "}
                    <a>
                      {" "}
                      <img alt="" className="media-object moneySendRespo" src={six} />{" "}
                    </a>{" "}
                  </div>
                  <div className="media-body">
                    <h4 className="media-heading text-black">Get updates on your transaction:</h4>
                    <div className="my-3">
                    You will receive different types updates regarding your transaction. You can simply visit on the transaction page to see tracking status of your transaction in every step. 
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Container>
        {/* <Container className="text-center">
          <Row className="text-center">
            <LightSpeed left duration={2000}>
              <h2 className="responsiveFontLargeHeading abtBefore purpleText bolder pb-2">How it work</h2>
              <h1 className="responsiveFontLargeMedium customerRes w-75 text-center d-inline-block justify-content-center m-auto">
                How to send money with LegalRemit
              </h1>
            </LightSpeed>
          </Row>

          <Row className="how maTopRes d-flex align-items-center responsiveMainForMobile">
            <div className="column col-lg-5 maTopRes">
              <Rotate right duration={2000}>
                <div className="card1 mb-5">
                  <div className="content">
                    <div className="front d-flex">
                      <div className="d-flex flex-column align-items-center">
                    
                          <h4 className="responsiveFontLargeMedium medium ms-4 p-0 m-0 me-4 mb-1">
                            Sign Up for a FREE account
                          </h4>
               
                        <Card.Text className="w-75 textEnd mb-0">
                          <small className="responsiveFontLarge  responsiveFontLarge blackShade textEnd">
                            We proudly introduce real-time remittance payments
                            from Australia to Nepal using our
                          </small>
                        </Card.Text>
                      </div>
                      <Image src={one} className=" droplet moneySendRespo" alt="bjb"></Image>
                    </div>
                    <div className="back from-right innerTextInner">
                      <p className="text-white innerTextInner">
                        You should create your profile using your name, email,
                        and mobile number. Email and mobile numbers must be
                        verified to continue using the verification link and OTP
                        sent in your mobile number. Name must be matched with
                        the name in your IDs.{" "}
                      </p>
                    </div>
                  </div>
                </div>

                <div className="card1 mb-5">
                  <div className="content">
                    <div className="front d-flex">
                      <div className="d-flex flex-column align-items-center">
                    
                          <h4 className="medium ms-4 p-0 m-0  mb-1 responsiveFontLargeMedium">
                            Enter your KYC details
                          </h4>
                 
                        <Card.Text className="w-75 textEnd mb-0">
                          <small className="responsiveFontLarge  responsiveFontLarge blackShade textEnd">
                            We proudly introduce real-time remittance payments
                            from Australia to Nepal using our
                          </small>
                        </Card.Text>
                      </div>
                      <Image src={two} className=" droplet moneySendRespo" alt="bjb"></Image>
                    </div>
                    <div className="backGreen from-right innerTextInner">
                      <p className="text-white innerTextInner">
                        Once you verify your mobile with OTP, we ask you to fill
                        in your KYC details. We need basic information about
                        your current residential address (Including house number
                        and postcode), your nationality, and your date of birth.
                        Please click on save and next to move to the next step{" "}
                      </p>
                    </div>
                  </div>
                </div>

                <div className="card1">
                  <div className="content ">
                    <div className="front d-flex">
                      <div className="d-flex flex-column align-items-center">
                 
                          <h4 className="responsiveFontLargeMedium medium p-0 m-0  mb-1 textEnd">
                            Upload your valid ID
                          </h4>
                 
                        <Card.Text className="w-75 textEnd mb-0">
                          <small className="responsiveFontLarge  blackShade textEnd responsiveFontLarge">
                            We proudly introduce real-time remittance payments
                            from Australia to Nepal using our
                          </small>
                        </Card.Text>
                      </div>
                      <Image src={three} className=" droplet moneySendRespo" alt="bjb"></Image>
                    </div>
                    <div className="backOrange from-right innerTextInner">
                      <p className="text-white innerTextInner">
                        We require you to upload one of the acceptable forms of
                        ID in this step. We only accept three types of IDs at
                        the moment. Passport (any country), Australian Driver's
                        license, and Australian Photo ID (also known as Age Card
                        proof issued by the state government). You should choose
                        the type of ID from the given list, provide an ID
                        number, Its expiry date, and issuing authority. Once you
                        fill out mentioned information, you can upload a copy of
                        your ID and submit your KYC to complete your profile.
                        Now you are ready to send money to your loved ones.
                        Please note that your uploaded ID must be readable and
                        your face should be clearly visible.{" "}
                      </p>
                    </div>
                  </div>
                </div>
              </Rotate>
            </div>
            <div className="col-lg-2">
              <Swing duration={2000}>
                <Image src={seven} className="moneySendRespo img-fluid p-0" alt="bjb"></Image>
              </Swing>
            </div>
            <div className="column col-lg-5 maTopRes">
              <Rotate left duration={2000}>
                <div className="card1 mb-5">
                  <div className="content">
                    <div className="front d-flex">
                      <Image src={six} className=" droplet moneySendRespo" alt="bjb"></Image>
                      <div className="d-flex flex-column align-items-center">
             
                          <h4 className="responsiveFontLargeMedium medium p-0 ms-5  m-0 mb-1 textStart">
                            Enter recipient’s details and choose a delivery
                            method
                          </h4>
                    
                        <Card.Text className="w-75 textStart mb-0">
                          <small className="responsiveFontLarge  responsiveFontLarge blackShade textStart">
                            We proudly introduce real-time remittance payments
                            from
                          </small>
                        </Card.Text>
                      </div>
                    </div>
                    <div className="backViolet from-left innerTextInner">
                      <p className="text-white innerTextInner">
                        After completing KYC, you can enter your desired amount
                        to send. Then you have to provide recipients' details
                        such as name, address, mobile number, relation, and bank
                        details. The required recipients’ details may vary
                        depending upon the delivery method, and the recipient’s
                        country.{" "}
                      </p>
                    </div>
                  </div>
                </div>

                <div className="card1 mb-5">
                  <div className="content">
                    <div className="front d-flex">
                      <Image src={five} className=" droplet moneySendRespo" alt="bjb"></Image>
                      <div className="d-flex flex-column align-items-center">
                      
                          <h4 className="responsiveFontLargeMedium medium  text-black me-5 p-0 m-0 pe-4 mb-1">
                            Pay to LegalRemit
                          </h4>
                    
                        <Card.Text className="w-75 textStart  mb-0">
                          <small className="responsiveFontLarge  responsiveFontLarge blackShade textStart">
                            We proudly introduce real-time remittance payments
                            from Australia to Nepal using our
                          </small>
                        </Card.Text>
                      </div>
                    </div>
                    <div className="backBlue from-left">
                      <p className="text-white innerTextInner">
                        After providing all the required information, now you
                        should choose your convenient payment method available
                        in our system. we have 4 payment options available. You
                        can pay us using our pay ID from your bank, you can make
                        a bank transfer using our BSB and Account Number as
                        well. You can pay us using POLI payments by logging into
                        your internet bank. We are adding payment options using
                        Debit cards and credit cards soon.
                      </p>
                    </div>
                  </div>
                </div>

                <div className="card1">
                  <div className="content">
                    <div className="front d-flex">
                      <Image src={four} className=" droplet moneySendRespo" alt="bjb"></Image>
                      <div className="d-flex flex-column align-items-center">
                   
                          <h4 className="responsiveFontLargeMedium medium p-0 m-0  mb-1 textStart">
                            Track transfers via app
                          </h4>
                  
                        <Card.Text className="w-75 ms-5 textStart mb-0">
                          <small className="responsiveFontLarge  responsiveFontLarge blackShade textStart">
                            We proudly introduce real-time remittance payments
                            from Australia to Nepal using our
                          </small>
                        </Card.Text>
                      </div>
                    </div>
                    <div className="backMaroon from-left innerTextInner">
                      <p className="text-white innerTextInner">
                        Now you can track your transaction on our APP and
                        website in real-time. We notify you with a popup
                        notification, in-app notification, SMS, and email
                        notification regarding your transaction.{" "}
                      </p>
                    </div>
                  </div>
                </div>
              </Rotate>
            </div>
          </Row>
        </Container> */}
      </section>
    </>
  );
}
