export let country_list = {
  
    "AE" : "AED", 
    "AS": "USD",
    "AD":"EUR",
    "AI":"XCD",
    "AF": "AFN",
    "AG": "XCD",
    "AT": "EUR",  
    "AL": "ALL",  
    "AM": "AMD",  
    "AW":"AWG",
    "AN": "ANG",  
    "AO": "AOA",  
    "AQ": "AQD",  
    "AR": "ARS",  
    "AU": "AUD", 
    "AZ": "AZN",  
    "BA": "BAM", 
    "BT":"BTN", 
    "BB": "BBD", 
    "BD": "BDT",  
    "BE": "XOF",  
    "BG": "BGN",  
    "BH": "BHD",  
    "BI": "BIF",  
    "BM": "BMD", 
    "BN": "BND", 
    "BO": "BOB",  
    "BR": "BRL",  
    "BS": "BSD", 
    "BV": "NOK",  
    "BW": "BWP",  
    "BY": "BYR",  
    "BZ": "BZD",  
    "CA": "CAD",  
    "CD": "CDF",  
    "CF": "XAF",  
    "CH": "CHF", 
    "CL": "CLP",  
    "CN": "CNY",  
    "CO": "COP",  
    "CR": "CRC",  
    "CU": "CUP",  
    "CV": "CVE",  
    "CY": "CYP",  
    "CZ": "CZK",  
    "DJ": "DJF",  
    "DK": "DKK", 
    "DO": "DOP",  
    "DZ": "DZD",  
    "EC": "ECS",  
    "EE": "EEK",  
    "EG": "EGP",  
    "ET": "ETB",  
    "FR": "EUR",  
    "FJ": "FJD",  
    "FK": "FKP",  
    "GB": "GBP",  
    "GE": "GEL",  
    "GG": "GGP",  
    "GH": "GHS",  
    "GI": "GIP",  
    "GM": "GMD",  
    "GN": "GNF",  
    "GT": "GTQ",  
    "GY": "GYD",  
    "HK": "HKD",  
    "HN": "HNL",  
    "HR": "HRK",  
    "HT": "HTG",  
    "HU": "HUF", 
    "ID": "IDR",  
    "IL": "ILS",  
    "IN": "INR",  
    "IQ": "IQD",  
    "IR": "IRR", 
    "IS": "ISK",  
    "JM": "JMD",  
    "JO": "JOD",  
    "JP": "JPY", 
    "KE": "KES",  
    "KG": "KGS",  
    "KH": "KHR",  
    "KM": "KMF",  
    "KP": "KPW",  
    "KR": "KRW",  
    "KW": "KWD",  
    "KY": "KYD",  
    "KZ": "KZT",  
    "LA": "LAK",  
    "LB": "LBP",  
    "LK": "LKR" , 
    "LR": "LRD" , 
    "LS": "LSL" , 
    "LT": "LTL" , 
    "LV": "LVL" , 
    "LY": "LYD" , 
    "MA": "MAD" , 
    "MD": "MDL" , 
    "MG": "MGA" , 
    "MK": "MKD" , 
    "MM": "MMK" , 
    "MN": "MNT" , 
    "MO": "MOP" , 
    "MR": "MRO" , 
    "MT": "MTL" , 
    "MU": "MUR" , 
    "MV": "MVR" , 
    "MW": "MWK" , 
    "MX": "MXN" , 
    "MY": "MYR" , 
    "MZ": "MZN" ,
    "NA": "NAD" , 
    "NC": "XPF" , 
    "NG": "NGN" , 
    "NI": "NIO" , 
    "NP": "NPR" , 
    "NZ": "NZD" , 
    "OM": "OMR" , 
    "PA": "PAB" , 
    "PE": "PEN" , 
    "PG": "PGK" , 
    "PH": "PHP" , 
    "PK": "PKR" , 
    "PL": "PLN" , 
    "PY": "PYG" , 
    "QA": "QAR" , 
    "RO": "RON" , 
    "RS": "RSD" , 
    "RU": "RUB" , 
    "RW": "RWF" , 
    "SA": "SAR" , 
    "SB": "SBD" , 
    "SC": "SCR" , 
    "SD": "SDG" , 
    "SE": "SEK" , 
    "SG": "SGD" , 
    "SK": "SKK" , 
    "SL": "SLL" , 
    "SO": "SOS" ,
    "SR": "SRD" , 
    "ST": "STD" ,
    "SV": "SVC" ,
    "SY": "SYP" ,
    "SZ": "SZL" ,
    "TH": "THB" , 
    "TJ": "TJS" ,
    "TM": "TMT" , 
    "TN": "TND" ,
    "TO": "TOP" , 
    "TR": "TRY" , 
    "TT": "TTD" ,
    "TW": "TWD" , 
    "TZ": "TZS" , 
    "UA": "UAH" , 
    "UG": "UGX" , 
    "US": "USD" , 
    "UY": "UYU" ,
    "UZ": "UZS" ,
    "VE": "VEF" ,
    "VN": "VND" ,
    "VU": "VUV" ,
    "YE": "YER" ,
    "ZM": "ZAR" ,
    "ZW": "ZMK"  
}