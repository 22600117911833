import React, { useEffect , useState} from "react";
import Footer from "./Footer/Footer";
import Partner from "./Footer/MoneyTransferCountry/Partners";
import MainParticle from "./MainParticle/MainParticle";
import MoneyExchangeCard from "./MoneyExchangeCard/MoneyExchangeCard";
import InternationalMoney from "./InternationMoney/InternationalMoney";
import Utilty from "./Utility/Utility";
import Navbar from "./Navbar/Navbar";
import "../../assets/assets/scss/pages/Home.scss";
import AboutSection from "./AboutSection/AboutSection";
import CurrencyExchange from "./CurrencyExchange/CurrencyExchange";
import Services from "./Services/Services";
import Clients from "./Clients/Clients";
import HowItWorks from "./HowItWorks/HowItWorks";
import CookiePolicy from "../Helpers/CookiePolicy/CookiePolicy";
import LatestNews from "../LatestNews/LatestNews";
import axios from "axios";
import { CommonConstants } from "../../Constants/common.constants";
import { Modal ,Button } from "react-bootstrap";
import { useHistory } from "react-router-dom";

export default function Home() {
  const history = useHistory()
  const [ChangePasswordAdmin, setChangePasswordAdmin] = useState(false)


  useEffect(() => {
    window.scrollTo(0, 0);
    if(localStorage.getItem("Id")){
      GetUsedDetails()
    }
  },[]);
  const GetUsedDetails = async () => {
    try {
      axios
      .post(CommonConstants.BASE_URL + "/getuserinfobyid", {
        id: localStorage.getItem("Id"),
      })
      .then((respo) => {
        if(respo.data.data.isAdminResetPwd == true){
          localStorage.setItem('AdminResetPermission',respo.data.data.isAdminResetPwd);
          setChangePasswordAdmin(true)
        }
      })
      .catch((err) => {
        console.log(err);
      });
    } catch (err) {
      console.log(err)
    }
  };
  return (
    <>
      <div className="cookieMain">
        <CookiePolicy></CookiePolicy>
      </div>
      <div className="bg-white ">
        <section className="bg-white mainLoginSection position-relative">
          <Navbar></Navbar>
          <MainParticle></MainParticle>
        </section>
        <MoneyExchangeCard></MoneyExchangeCard>
        <InternationalMoney></InternationalMoney>
        <Utilty></Utilty>
        <AboutSection></AboutSection>
        <CurrencyExchange></CurrencyExchange>
        <Services></Services>
        <Clients></Clients>
        <HowItWorks></HowItWorks>
        <LatestNews id="latestNews" />

        <Partner></Partner>
        <Footer></Footer>
        
      </div>
      <Modal
          show={ChangePasswordAdmin}
          // onHide={() => {
          //   setChangePasswordAdmin(false);
          // }}
          backdrop="static"
          keyboard={false}
          size="md"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title className="purpleText">Alert</Modal.Title>
          </Modal.Header>
          <Modal.Body>
          It seems your password has been changed by the Admin. Please re-login to the application
          </Modal.Body>
          <Modal.Footer className="justify-content-center">
            <div className="col-lg-6">
              <Button
                variant="secondary"
                className="purpleBackground"
                onClick={(e) => {
                  setChangePasswordAdmin(false);
                  history.push('/changepassword')
                }}
              >
                OK
              </Button>
            </div>
          </Modal.Footer>
        </Modal>
    </>
  );
}
